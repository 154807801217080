import { useTranslation } from 'react-i18next'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import paper from '@/assets/home/PaperClip.svg'
import doc from '@/assets/home/doc.svg'
import trash from '@/assets/home/trash.svg'
import styles from './ConfirmDocument.module.scss'
import { useError } from '@/shared'
import { Tooltip } from 'antd'
import { getSha256 } from '@/utils'
import { Info } from '@app/icons'
import { uploadFile } from '@/shared/api/rest/pension'

export const ConfirmDocument = ({
	getDocument,
	fileList,
	maxSize = 10485760,
	getErrorSize,
	fileType = '',
	maxFiles = Infinity,
}) => {
	const { t } = useTranslation()
	const { getInputProps, acceptedFiles, fileRejections } = useDropzone({
		noClick: true,
		maxSize: maxSize,
		accept: ['.jpg', '.jpeg', '.png', '.pdf'],
	})
	const [files, setFiles] = useState([])
	useEffect(() => {
		// Если fileList не undefined или null
		if (fileList) {
			// Если fileList является массивом
			if (Array.isArray(fileList)) {
				setFiles((prevFiles) => [...prevFiles, ...fileList])
			}
			// Если fileList является объектом
			else if (fileList.sha256hex) {
				setFiles((prevFiles) => [...prevFiles, fileList])
			}
		}
	}, [])
	const [errorSize, setErrorSize] = useState(false)
	const { errors, setError } = useError()
	useEffect(() => {
		if (acceptedFiles.length > 0) onUploadFile(acceptedFiles[0])
		if (fileRejections?.length > 0) {
			const error = fileRejections[0]?.errors?.filter(({ code }) => code === 'file-invalid-type').length
				? t('wrong_type_photo_error')
				: t('pension.payments.form.doc_form.max_file_size_error')
			setError('image', error)
		}
	}, [acceptedFiles, fileRejections])

	const onUploadFile = useCallback(
		async (file) => {
			try {
				if (files.length >= maxFiles) {
					setError('file', t('You cannot upload more than {count} files', { count: maxFiles }))
					return
				}
				const formData = new FormData()
				getSha256(file).then(async (arr) => {
					const fileListArray = Array.isArray(fileList) ? fileList : [fileList]
					const isFound = [...fileListArray, ...files].some((element) => {
						return element && element.sha256hex === arr
					})
					if (isFound) {
						setError('file', t('pension.payments.form.doc_form.file_added'))
					} else {
						formData.set('file', file)
						const data = await uploadFile(formData, fileType)
						setFiles([...files, data])
					}
				})
			} catch (error) {
				console.error('[error uploading file] ', error)
			}
		},
		[files]
	)

	useEffect(() => {
		getDocument(files)
		const filesSizeByte = files.reduce((acc, file) => (file.fileSize ? file.fileSize + acc : acc), 0)
		if (filesSizeByte >= maxSize) {
			setErrorSize(true)
			getErrorSize(true)
		} else {
			setErrorSize(false)
			getErrorSize(false)
		}
	}, [files, getDocument])

	const filesSize = useMemo(() => (files.reduce((acc, file) => file.fileSize + acc, 0) / 1e6).toFixed(1), [files])

	const hint = useMemo(() => t('labor_records.max_size_all'), [t])
	const uploadDisabled = useMemo(() => files.length >= maxFiles, [files, maxFiles])

	return (
		<div className={styles.wrapper}>
			<ul className={styles.file}>
				{files.map(({ fileName }, idx) => (
					<li key={idx}>
						<div>
							<img src={doc} />
							<span>{fileName}</span>
						</div>
						<div
							className={styles.file__delete}
							onClick={() => {
								const newFiles = [...files]
								newFiles.splice(idx, 1)
								setFiles(newFiles)
							}}
						>
							<img src={trash} />
							<span>{t('remove')}</span>
						</div>
					</li>
				))}
			</ul>
			{errors.image && <p className={styles.error}>{errors.image}</p>}
			{filesSize > 0.0 ? (
				<div className={styles.size}>
					{' '}
					<Tooltip
						placement="right"
						color="white"
						overlayInnerStyle={{ color: '#5B6880', width: '329px' }}
						title={hint}
					>
						{t('labor_records.full_size') + ' (' + filesSize + ' МБ) '}
						<Info className={styles.hintIcon} />
					</Tooltip>
				</div>
			) : null}
			{errorSize ? (
				<p className={styles.error}>{t('labor_records.error_size')}</p>
			) : uploadDisabled ? (
				<div className={styles.formDisableBtn}>
					<span>{t('profUnions.cannotUpload')}</span>
				</div>
			) : (
				<div className={styles.formBtn}>
					<div className="greenBtn">
						<label className="input-file">
							<input type="file" {...getInputProps()} accept="image/jpeg,image/png,application/pdf" />
							<div className="custom-file">
								<img src={paper} />
								<span>{t('attache_file')}</span>
							</div>
						</label>
					</div>
				</div>
			)}
		</div>
	)
}
