import * as types from './types'

export const initialContract = {
	bin: '',
	enterprise_name: '',
	iin: '',
	fullName: '',
	isElectronic: false,
	contractNumber: '',
	registerDate: '',
	dateFrom: '',
	dateTo: '',
	destablishedPostCode: '',
	dpositionCode: '',
	dworkingConditionsCode: '',
	externalId: '',
	job_type: '',
	dworkingHoursCode: '',
	tariffRate: '',
	dcountryCode: '',
	dregionCode: '',
	ddistrictCode: '',
	dlocalityCode: '',
	workingPlace: '',
	isRemote: false,
	professionalsSkills: [],
	personalQualities: [],
	skillsList: [],
	specSkillsList: [],
	dcontractDateCode: '',
	dContractState: '',
	dContractStatus: '',
	dharmfulConditionsTypeCode: '',
	files: [],
	foreignLaborInfo: {
		codes: {
			value1: null,
			value2: null,
			value3: null,
		},
		dnonResidentAttractingBasisCode: null,
		isMigration: null,
		dnonResidentEducationCode: null,
		nonResidentPermitDate: null,
		nonResidentPermitNumber: null,
	},
	isResident: null,
}

const initialParams = {
	bin: '',
	companyName: '',
	contractNumber: '',
	dateFrom: '',
	dateTo: '',
	dcontractStateCode: null,
	dcontractStatusCode: null,
	dcontractTypeCode: null,
	employeeName: '',
	establishedPost: '',
	registerDate: '',
	sortDirection: '',
	sortField: '',
	srcText: '',
	terminationDate: null,
}

const initialState = {
	isLoading: false,
	isSuccess: false,
	error: null,
	isSend: false,
	contract: {
		data: initialContract,
		isLoading: false,
		isSuccess: false,
		hasError: false,
	},
	contracts: [],
	selectedContracts: [],
	createContractXML: null,
	removeXML: null,
	recoverXML: {
		open: false,
		data: null,
		response: null,
		error: null,
	},
	editXML: null,
	editSuccess: false,
	additionalContract: {
		contract: null,
		xml: null,
		isLoading: false,
		submitted: false,
	},
	pageable: null,
	totalPages: 0,
	totalElements: 0,
	last: false,
	number: 0,
	sort: null,
	size: 0,
	first: false,
	numberOfElements: 0,
	empty: false,
	terminationReasons: [],
	card: {
		submitted: false,
	},
	reorganize: {
		checkData: null,
		loading: false,
		error: null,
		xml: null,
		xmlLoading: false,
		result: null,
		isSuccess: false,
	},
	binEmployersListToChange: null,
	reorgonizeXml: null,
	updateIin: {
		xmlForUpdateIin: null,
		flData: null,
		updateIinResponse: null,
		error: null,
	},
	params: initialParams,
	socialLeaveIsLoading: false,
	socialLeaveIsSuccess: false,
	prolongationIsSuccess: false,
	prolongationIsLoading: false,
	isEdit: false,
	socialLeaveXML: null,
	prolongationXML: null,
	packageLoading: {
		loading: false,
		error: null,
		xml: null,
		result: null,
	},
	terminationXML: null,
	terminationIsLoading: false,
	terminationIsSuccess: false,
	etd: {
		isLoading: false,
		isSuccess: false,
		list: {
			data: [],
			numberOfElements: 10,
			totalElements: 30,
			totalPages: 3,
		},
		filter: {
			status: null,
			iin: null,
			date: null,
		},
		params: {
			page: 0,
			size: 10,
			rowsPerPage: [
				{
					size: 10,
					label: '10',
				},
				{
					size: 50,
					label: '50',
				},
				{
					size: 100,
					label: '100',
				},
			],
		},
		error: null,
		currentEtd: {
			data: null,
			loading: false,
			error: null,
		},
		currentEtdEdit: {
			data: null,
			loading: false,
			error: null,
		},
		saveProject: {
			data: null,
			loading: false,
			error: null,
		},
		sendToEmployee: {
			data: null,
			loading: false,
			error: null,
		},
		declineEmployee: {
			data: null,
			loading: false,
			error: null,
		},
		sendForImprovementEmployee: {
			data: null,
			loading: false,
			error: null,
		},
		declineEmployer: {
			data: null,
			loading: false,
			error: null,
		},
		deleteEtd: {
			data: null,
			loading: false,
			error: null,
		},
		status: null,
		image: null,
		key: null,
	},
}

export const reducer = (state = initialState, actions) => {
	const { type, payload } = actions
	switch (type) {
		case types.TOGGLE_LOADING:
			return { ...state, isLoading: payload }
		case types.TOGGLE_SUCCESS:
			return { ...state, isSuccess: payload }
		case types.TOGGLE_ERROR:
			return { ...state, error: payload }
		case types.SET_CONTRACT:
			return { ...state, contract: { ...state.contract, data: payload } }
		case types.SET_CONTRACT_LOADING:
			return { ...state, contract: { ...state.contract, isLoading: payload } }
		case types.SET_CONTRACT_SUCCESS:
			return { ...state, contract: { ...state.contract, isSuccess: payload } }
		case types.SET_CONTRACTS:
			return {
				...state,
				contracts: payload.content,
				pageable: payload.pageable,
				totalPages: payload.totalPages,
				totalElements: payload.totalElements,
				last: payload.last,
				number: payload.number,
				sort: payload.sort,
				size: payload.size,
				first: payload.first,
				numberOfElements: payload.numberOfElements,
				empty: payload.empty,
			}
		case types.SET_CREATE_CONTRACT_XML:
			return { ...state, createContractXML: payload }
		case types.SET_REMOVE_XML:
			return { ...state, removeXML: payload }
		case types.SET_EDIT_XML:
			return { ...state, editXML: payload }
		case types.SET_INITIAL_CONTRACT:
			return { ...state, contract: initialContract }
		case types.SET_CONTRACT_FIELDS:
			return { ...state, contract: { ...state.contract, data: { ...state.contract.data, ...payload } } }
		case types.SET_TERMINATION_REASONS:
			return { ...state, terminationReasons: payload }
		case types.SET_ADDITIONAL_CONTRACT_XML:
			return {
				...state,
				additionalContract: {
					...state.additionalContract,
					xml: payload,
				},
			}
		case types.SET_ADDITIONAL_CONTRACT_IS_LOADING:
			return {
				...state,
				additionalContract: {
					...state.additionalContract,
					isLoading: payload,
				},
			}
		case types.SET_ADDITIONAL_CONTRACT_SUBMITTED:
			return {
				...state,
				additionalContract: {
					...state.additionalContract,
					submitted: true,
				},
			}
		case types.RESET_ADDITIONAL_CONTRACT:
			return {
				...state,
				additionalContract: {
					...initialState.additionalContract,
				},
			}
		case types.SET_ADDITIONAL_CONTRACT:
			return {
				...state,
				additionalContract: {
					...state.additionalContract,
					contract: payload,
				},
			}
		case types.SET_CONTRACT_CARD_SUBMITTED:
			return {
				...state,
				card: {
					...state.card,
					submitted: true,
				},
			}
		case types.GET_DATA_BY_BIN_START:
			return {
				...state,
				reorganize: {
					...state.reorganize,
					checkData: null,
					error: null,
					loading: true,
					xml: null,
					xmlLoading: false,
					isSuccess: false,
				},
			}
		case types.GET_XML_TO_REORGANIZE_LOADING:
			return {
				...state,
				reorganize: {
					...state.reorganize,
					error: null,
					xmlLoading: true,
					xml: null,
					isSuccess: false,
				},
			}
		case types.CLEAR_XML_TO_REORGANIZE:
			return {
				...state,
				reorganize: {
					...state.reorganize,
					xmlLoading: false,
					xml: null,
					isSuccess: false,
				},
			}
		case types.SEND_SIGNED_XML_REORGANIZE:
			return {
				...state,
				reorganize: {
					...state.reorganize,
					xmlLoading: false,
					xml: null,
					isSuccess: true,
					result: payload,
				},
			}
		case types.SET_REORGANIZE_SUCCESS:
			return { ...state, reorganize: { ...state.reorganize, xmlLoading: false, isSuccess: false, result: payload } }
		case types.GET_REORGANIZE_XML:
			return {
				...state,
				reorganize: {
					...state.reorganize,
					error: null,
					loading: false,
					xmlLoading: false,
					isSuccess: false,
					xml: payload,
				},
			}
		case types.GET_DATA_BY_BIN:
			return {
				...state,
				reorganize: {
					...state.reorganize,
					checkData: payload,
					error: null,
					loading: false,
					xmlLoading: false,
					xml: null,
					isSuccess: false,
				},
			}
		case types.GET_DATA_BY_BIN_ERROR:
			return {
				...state,
				reorganize: {
					...state.reorganize,
					checkData: null,
					error: payload,
					loading: false,
					xmlLoading: false,
					xml: null,
					isSuccess: false,
				},
			}
		case types.REORGANIZE_ERROR:
			return {
				...state,
				reorganize: {
					...state.reorganize,
					error: payload,
					loading: false,
					xmlLoading: false,
					xml: null,
					isSuccess: false,
				},
			}
		case types.CLEAR_DATA_BY_BIN:
			return {
				...state,
				reorganize: initialState.reorganize,
			}
		case types.GET_EMPLOYERS_LIST_TO_CHANGE:
			return {
				...state,
				binEmployersListToChange: payload,
			}
		case types.GET_XML_FOR_UPDATE_IIN:
			return {
				...state,
				updateIin: {
					...state.updateIin,
					xmlForUpdateIin: payload,
				},
			}
		case types.GET_FL_DATA:
			return {
				...state,
				updateIin: {
					...state.updateIin,
					flData: payload,
				},
			}
		case types.SEND_XML_FOR_UPDATE_IIN:
			return {
				...state,
				updateIin: {
					...state.updateIin,
					updateIinResponse: payload,
				},
			}
		case types.SET_ERROR_UPDATE_IIN:
			return {
				...state,
				updateIin: {
					...state.updateIin,
					error: payload,
				},
			}
		case types.RESET_UPDATE_IIN:
			return {
				...state,
				updateIin: initialState.updateIin,
			}
		case types.SET_RECOVER_XML:
			return {
				...state,
				recoverXML: {
					...state.recoverXML,
					...payload,
				},
			}
		case types.UPDATE_CONTRACT_AFTER_RECOVER:
			return {
				...state,
				contract: {
					...state.contract,
					dcontractStatus: payload.status,
				},
				contracts: state.contracts.map((contract) => {
					if (contract.externalId === payload.externalId) {
						return { ...contract, dcontractStatus: payload.status }
					}
					return contract
				}),
			}
		case types.UPDATE_CONTRACT_AFTER_UPDATE_IIN:
			return {
				...state,
				contract: {
					...state.contract,
					fullName: payload.fullName,
					iin: payload.iin,
				},
			}
		case types.SET_EDIT_SUCCESS:
			return {
				...state,
				editSuccess: payload,
			}
		case types.RESET_EDITING:
			return {
				...state,
				editSuccess: false,
				editXML: null,
			}
		case types.SET_PARAMS:
			return {
				...state,
				params: payload,
			}
		case types.SET_SOCIAL_LEAVE_IS_LOADING:
			return {
				...state,
				socialLeaveIsLoading: payload,
			}
		case types.SET_SOCIAL_LEAVE_IS_SUCCESS:
			return {
				...state,
				socialLeaveIsSuccess: payload,
			}
		case types.SET_SOCIAL_LEAVE_XML:
			return {
				...state,
				socialLeaveXML: payload,
			}
		case types.RESET_SOCIAL_LEAVE:
			return {
				...state,
				socialLeaveXML: null,
				socialLeaveIsSuccess: false,
				socialLeaveIsLoading: false,
			}
		case types.SET_PROLONGATION_IS_SUCCESS:
			return {
				...state,
				prolongationIsSuccess: payload,
			}
		case types.SET_PROLONGATION_IS_LOADING:
			return {
				...state,
				prolongationIsLoading: payload,
			}
		case types.SET_PROLONGATION_XML:
			return {
				...state,
				prolongationXML: payload,
			}
		case types.RESET_PROLONGATION:
			return {
				...state,
				prolongationXML: null,
				prolongationIsSuccess: false,
				prolongationIsLoading: false,
			}
		case types.SET_TERMINATE_XML:
			return {
				...state,
				terminationXML: payload,
			}
		case types.SET_TERMINATION_IS_LOADING:
			return {
				...state,
				terminationIsLoading: payload,
			}
		case types.SET_TERMINATION_IS_SUCCESS:
			return {
				...state,
				terminationIsSuccess: payload,
			}
		case types.RESET_TERMINATION:
			return {
				...state,
				terminationXML: null,
				terminationIsLoading: false,
				terminationIsSuccess: false,
			}
		case types.SET_SELECTED_CONTRACTS_STATE:
			return {
				...state,
				selectedContracts: payload,
			}
		case types.GET_XML_TO_SIGN_SOME_LOADING:
			return {
				...state,
				packageLoading: {
					...state.packageLoading,
					error: null,
					loading: true,
					xml: null,
				},
			}
		case types.GET_XML_TO_SIGN_SOME:
			return {
				...state,
				packageLoading: {
					...state.packageLoading,
					error: null,
					loading: true,
					xml: payload,
				},
			}
		case types.GET_XML_TO_SIGN_SOME_ERROR:
			return {
				...state,
				packageLoading: {
					...state.packageLoading,
					error: payload,
					loading: false,
					xml: null,
					result: null,
				},
			}
		case types.SEND_SIGNED_XML_PACKAGE_SOME:
			return {
				...state,
				packageLoading: {
					...state.packageLoading,
					error: null,
					xml: null,
					loading: false,
					result: payload,
				},
			}
		case types.SET_TERMINATE_XML:
			return {
				...state,
				packageLoading: {
					...state.packageLoading,
					xml: payload,
					error: null,
					loading: false,
				},
			}
		case types.DELETE_PACKAGE_SOME:
			return {
				...state,
				packageLoading: {
					...state.packageLoading,
					error: null,
					xml: null,
					loading: false,
					result: payload,
				},
			}
		case types.RESET_PACKAGE:
			return {
				...state,
				selectedContracts: [],
				packageLoading: {
					...state.packageLoading,
					error: null,
					xml: null,
					loading: false,
					result: null,
				},
			}
		case types.SET_IS_EDIT:
			return {
				...state,
				isEdit: payload,
			}
		case types.RESET_CONTRACT:
			return { ...state, contract: { ...state.contract, hasError: false, isSuccess: false, isLoading: false } }
		case types.SET_CONTRACT_ERROR:
			return { ...state, contract: { ...state.contract, hasError: payload } }

		// ЭТД
		case types.ETD_TOGGLE_LOADING:
			return { ...state, etd: { ...state.etd, isLoading: payload } }
		case types.ETD_TOGGLE_SUCCESS:
			return { ...state, etd: { ...state.etd, isSuccess: payload } }
		case types.ETD_SET_FILTER:
			return { ...state, etd: { ...state.etd, filter: payload } }
		case types.ETD_SET_PARAMS:
			return { ...state, etd: { ...state.etd, params: payload } }
		case types.ETD_SET_CURRENT_ETD:
			return { ...state, etd: { ...state.etd, currentEtd: payload } }
		case types.ETD_SET_CURRENT_ETD_EDIT:
			return { ...state, etd: { ...state.etd, currentEtdEdit: payload } }
		case types.ETD_SAVE_PROJECT:
			return { ...state, etd: { ...state.etd, saveProject: payload } }
		case types.ETD_SEND_TO_EMPLOYEE:
			return { ...state, etd: { ...state.etd, sendToEmployee: payload } }
		case types.ETD_DECLINE_EMPLOYEE:
			return { ...state, etd: { ...state.etd, declineEmployee: payload } }
		case types.ETD_SEND_FOR_IMPROVEMENT_EMPLOYEE:
			return { ...state, etd: { ...state.etd, sendForImprovementEmployee: payload } }
		case types.ETD_DECLINE_EMPLOYER:
			return { ...state, etd: { ...state.etd, declineEmployer: payload } }
		case types.DELETE_ETD:
			return { ...state, etd: { ...state.etd, deleteEtd: payload } }
		case types.ETD_STATUS_SIGN:
			return { ...state, etd: { ...state.etd, status: payload } }
		case types.ETD_IMAGE:
			return { ...state, etd: { ...state.etd, image: payload } }
		case types.ETD_IMAGE_KEY:
			return { ...state, etd: { ...state.etd, key: payload } }
		case types.ETD_ERROR:
			return { ...state, etd: { ...state.etd, error: payload } }
		case types.RESET_ETD_QR:
			return {
				...state,
				etd: { ...state.etd, status: null, image: null, error: null, isLoading: null, isSuccess: null, key: null },
			}
		default:
			return state
	}
}
