import { Button, ModalTemplate } from '@/ui'
import { UnionsData } from '@features/profUnions/info-table/UnionsData'
import { Docs } from '@features/profUnions/info-table/Docs'
import { Composition } from '@features/profUnions/info-table/Composition'
import React, { useEffect } from 'react'
import styles from './View.module.scss'
import { useTranslation } from 'react-i18next'
import { getFromLocalStorage } from '@/shared'
import { useDispatch, useSelector } from 'react-redux'
import { profUnionsSelectors, profUnionsThunks } from '@/entity'
import { LoadingBlocker } from '@/components/Loader'

export const View = ({ onClose, onToggleAction, tradeUnion, myUnion = false }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const activeRole = getFromLocalStorage('activeRole')
	const isLoading = useSelector(profUnionsSelectors.isLoading)
	const isSuccess = useSelector(profUnionsSelectors.isSuccess)

	/*	useEffect(() => {
		if (isSuccess) {
			onClose()
		}
	}, [isSuccess])*/

	const addSelfTradeUnion = (externalId) => {
		const today = new Date()
		const day = today.getDate().toString().padStart(2, '0')
		const month = (today.getMonth() + 1).toString().padStart(2, '0')
		const dateIn = `${day}.${month}.${today.getFullYear()}`
		const person = { dateIn: dateIn }
		dispatch(profUnionsThunks.addSelf(externalId, person))
	}

	return (
		<>
			<ModalTemplate onClose={onClose}>
				<div className={`scroll ${styles.wrapper}`}>
					<UnionsData tradeUnion={tradeUnion} isEmployee={'ROLE_EMPLOYEE' === activeRole}></UnionsData>
					<Docs tradeUnion={tradeUnion} onToggleAction={onToggleAction} myUnion={myUnion}></Docs>
					<Composition tradeUnion={tradeUnion} onToggleAction={onToggleAction} myUnion={myUnion}></Composition>
					<div className={styles.buttonEnd}>
						{myUnion ? (
							<div className={styles.mr}>
								<Button onClick={() => addSelfTradeUnion(tradeUnion.externalId)}>{t('actions.join')}</Button>
							</div>
						) : null}
						<Button onClick={onClose}>{t('close')}</Button>
					</div>
				</div>
			</ModalTemplate>
			{isLoading ? <LoadingBlocker /> : null}
		</>
	)
}
