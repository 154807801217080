import { useTranslation } from 'react-i18next'
import { DataGridTable } from '@/ui'
import styles from './UnionsData.module.scss'
import { useSelector } from 'react-redux'
import { contractActionsSelectors } from '@features/contracts/model'
import { getCurrentLanguage } from '@/i18next'

const status = {
	DRAFT: 'profUnions.not_approved',
	APPROVED: 'profUnions.approved',
}

// Функция для поиска соответствующего объекта и получения его значения в зависимости от языка
const findObjectName = (objArray, code, lang) => {
	const foundObject = objArray?.find((obj) => obj.code === code)
	return foundObject ? foundObject[lang] || '-' : '-'
}

export const UnionsData = ({ tradeUnion, isEmployee }) => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	const options = useSelector(contractActionsSelectors.options)
	const { unionType, okedsTop, districts } = options

	// Мы используем нашу функцию здесь для уменьшения дублирования кода
	const typeName = findObjectName(unionType, tradeUnion.typeCode, lang)
	const parentTypeName = findObjectName(unionType, tradeUnion.parentType, lang)
	const industryName = findObjectName(okedsTop, tradeUnion.industryCode, lang)
	const parentIndustryName = findObjectName(okedsTop, tradeUnion.parentIndustry, lang)

	const profUnionInformation = [
		...(!isEmployee ? [{ title: t('bin'), data: tradeUnion?.bin }] : []),
		{ title: t('name'), data: tradeUnion?.unionName },
		{
			title: t('region'),
			data: districts
				.filter((district) => district.code === tradeUnion?.kato)
				.map((item) => item[getCurrentLanguage()])[0],
		},
		{ title: t('profUnions.industry'), data: industryName },
		{ title: t('contracts_tab.type'), data: typeName },
		{ title: t('col_contract.bin'), data: tradeUnion?.employers?.length ? tradeUnion.employers[0].employerBin : '' },
		{ title: t('col_contract.name'), data: tradeUnion?.employers?.length ? tradeUnion.employers[0].employerName : '' },
	]

	const profUnionInformation2 = [
		{ title: t('bin'), data: tradeUnion?.parentBin },
		{ title: t('name'), data: tradeUnion?.parentName },
		{ title: t('profUnions.industry'), data: parentIndustryName },
		{ title: t('contracts_tab.type'), data: parentTypeName },
		{ title: t('contracts_tab.status'), data: t(status[tradeUnion.status]) || '-' },
	]

	return (
		<div className={styles.unionInfo}>
			<DataGridTable
				subTitle={typeName}
				title={'profUnions.information'}
				information={profUnionInformation}
				titleClass={styles.tableTitleCell}
			/>
			{tradeUnion.typeCode !== 'industry' ? (
				<DataGridTable
					subTitle={t('profUnions.structInfoUnit') + ' ' + tradeUnion.parentName}
					information={profUnionInformation2}
					titleClass={styles.tableTitleCell}
				/>
			) : null}
		</div>
	)
}
