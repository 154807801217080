import { connect, Field as FormikField } from 'formik'
import { useTranslation } from 'react-i18next'
import { formFieldNames } from '@/entity'
import React, { useCallback } from 'react'
import { Field } from '@/ui'

export const CountryField = connect(({ options = [], formik: { setFieldValue, values }, isFormDirty }) => {
	const { t } = useTranslation()

	return (
		<FormikField name={formFieldNames.countryWork}>
			{({ field, meta: { touched, error } }) => {
				const onChange = useCallback((code) => {
					setFieldValue(formFieldNames.countryWork, code)
					setFieldValue(formFieldNames.districtWork, null)
					setFieldValue(formFieldNames.regionWork, null)
					setFieldValue(formFieldNames.locality, null)
				}, [])

				return (
					<Field
						options={options}
						error={(touched || isFormDirty) && error}
						type="text"
						fieldType="selectSearch"
						isRequired={values[formFieldNames.remoteWork] !== '1'}
						label={t('contracts_tab.country_work')}
						placeholder={t('choose_from_handbook')}
						{...field}
						onChange={onChange}
					/>
				)
			}}
		</FormikField>
	)
})
