import { ModalTemplate } from '@/ui'
import styles from './ProfUnions.module.scss'
import { ProfUnionsForm } from '@/entity/profUnions/ui/form'
import { initialProfUnions } from '@/entity/profUnions/model/reducer'
import { useDispatch, useSelector } from 'react-redux'
import { contractActionsSelectors } from '@features/contracts/model'
import { profUnionsActions, profUnionsSelectors, profUnionsThunks, userSelectors } from '@/entity'
import { useEffect } from 'react'
import { useDigitalSign } from '@/shared'

export const ProfUnions = ({ onCancel, actions, activeCompany, cabinetTradeUnion, tradeUnion }) => {
	const dispatch = useDispatch()
	const options = useSelector(contractActionsSelectors.options)
	const profUnionXml = useSelector(profUnionsSelectors.xml)
	const isSuccess = useSelector(profUnionsSelectors.isSuccess)
	const isEmployee = useSelector(userSelectors.activeRole) === 'ROLE_EMPLOYEE'
	const { successPayload, onSign, failedPayload, onClearPayload } = useDigitalSign()

	const onClear = () => {
		onClearPayload()
		dispatch(profUnionsActions.resetXml())
		dispatch(profUnionsActions.toggleSuccess(false))
		if (isSuccess) {
			dispatch(profUnionsActions.reset())
			onCancel()
		}
	}

	let profUnions
	if (actions.edit) {
		// Если у tradeUnion есть массив employers и он содержит хотя бы один элемент
		if (tradeUnion.employers && tradeUnion.employers.length > 0) {
			// Получение employerBin и employerName из первого элемента массива employers
			let { employerBin, employerName } = tradeUnion.employers[0]
			// Удаление employers из объекта tradeUnion
			let { employers, ...restTradeUnion } = tradeUnion
			// Включение employerBin и employerName в объект profUnions
			profUnions = { ...restTradeUnion, employerBin, employerName }
		} else {
			profUnions = tradeUnion
		}
	} else if (!isEmployee) {
		profUnions = cabinetTradeUnion
			? { ...initialProfUnions, bin: activeCompany.bin, unionName: activeCompany.companyName }
			: { ...initialProfUnions, parentBin: activeCompany.bin, parentName: activeCompany.companyName }
	} else {
		profUnions = initialProfUnions
	}

	const onSubmit = (data) => {
		dispatch(profUnionsThunks.getXml(data))
	}

	useEffect(() => profUnionXml && onSign(profUnionXml), [profUnionXml])

	useEffect(() => {
		if (successPayload) {
			dispatch(profUnionsThunks.sendSignedProfUnions(successPayload, isEmployee, cabinetTradeUnion))
			onClear()
		}
	}, [successPayload])

	useEffect(() => {
		failedPayload && onClear()
	}, [failedPayload])

	return (
		<ModalTemplate onClose={onCancel}>
			<div className={`scroll ${styles.wrapper}`}>
				<ProfUnionsForm
					initialValues={profUnions}
					options={options}
					onCancel={onCancel}
					onSubmit={onSubmit}
					actions={actions}
					cabinetTradeUnion={cabinetTradeUnion}
				></ProfUnionsForm>
			</div>
		</ModalTemplate>
	)
}
