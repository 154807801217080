import { useTranslation } from 'react-i18next'
import { getCurrentLanguage } from '@/i18next'
import styles from './MyUnoinsTable.module.scss'
import React, { useCallback, useMemo, useState } from 'react'
import { headCells } from './myUnionsTable.headings'
import DropDownMenu from '@/components/DropdownMenu/DropdownMenu'
import { useDispatch, useSelector } from 'react-redux'
import { contractActionsSelectors } from '@features/contracts/model'
import onActionMenuClickMembers from './onActionMenuClickMyUnion'
import { profUnionsSelectors, profUnionsThunks } from '@/entity'
import { LoadingScreen } from '@/components/Loader'
import { Title } from '@/ui'
import { colorsMyUnion, statusMyUnion } from '@features/profUnions/table/utils'

export const MyUnionsTable = ({ onToggleAction, myUnions }) => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	const dispatch = useDispatch()
	const options = useSelector(contractActionsSelectors.options)
	const { unionType } = options
	const [actionMenuItems, setActionMenuItems] = useState([])
	const isLoading = useSelector(profUnionsSelectors.isLoading)
	const isSuccess = useSelector(profUnionsSelectors.isSuccess)
	const error = useSelector(profUnionsSelectors.error)

	const tableHeader = useMemo(
		() => (
			<>
				{headCells.map((head, index) => (
					<th key={index + 'thead-th'}>{t(head.label)}</th>
				))}
			</>
		),
		[t]
	)

	const getRequest = useCallback((externalId) => dispatch(profUnionsThunks.getUnionTrade(externalId)), [dispatch])

	const addSelfTradeUnion = (externalId) => {
		const today = new Date()
		const day = today.getDate().toString().padStart(2, '0')
		const month = (today.getMonth() + 1).toString().padStart(2, '0')
		const dateIn = `${day}.${month}.${today.getFullYear()}`
		const person = { dateIn: dateIn }
		dispatch(profUnionsThunks.addSelf(externalId, person))
	}

	const onActionMenuChoose = (mode, row) => {
		switch (mode) {
			case 'view':
				getRequest(row.externalId)
				break
			case 'join':
				addSelfTradeUnion(row.externalId)
				break
			default:
				break
		}
		onToggleAction(mode, true)
	}

	const fastView = (row) => {
		onActionMenuChoose('view', row)
	}
	const getTableRow = useCallback(
		(row, index) => {
			const typeObject = unionType.find((unionObject) => unionObject.code === row.typeCode)
			let typeName = '-'
			if (typeObject) {
				typeName = typeObject[lang] || '-'
			}
			return (
				<tr key={index} onClick={() => fastView(row)}>
					<td>{row.dateCreate}</td>
					<td>{typeName}</td>
					<td>{row.unionName}</td>
					<td>
						<span style={{ color: colorsMyUnion[row?.approvedPerson] || '' }}>
							{t(statusMyUnion[row?.approvedPerson]) || '-'}
						</span>
					</td>
					<td>{row.personCount}</td>
					<td
						onClick={(e) => {
							e.stopPropagation()
							onActionMenuClickMembers(setActionMenuItems, t)
						}}
					>
						<DropDownMenu
							title={t('select_pls')}
							items={actionMenuItems}
							onItemClick={(mode) => onActionMenuChoose(mode, row)}
						/>
					</td>
				</tr>
			)
		},

		[actionMenuItems, lang, onActionMenuChoose, t]
	)

	const tableBody = useMemo(() => myUnions?.map((row, index) => getTableRow(row, index)), [myUnions, getTableRow])

	const tableLoading = useMemo(
		() => (
			<tr>
				<td colSpan={11} className={styles.messageCol}>
					{(isLoading || (!error && !isLoading && !isSuccess)) && (
						<LoadingScreen>
							<Title>{t('wait')}</Title>
							<Title>{t('labor_records.loading')}</Title>
						</LoadingScreen>
					)}
					{!isLoading && isSuccess && myUnions.length < 1 && (
						<div className={styles.tableLoading}>{t('profUnions.noDataTradeUnion')}</div>
					)}
					{error && !isLoading && !isSuccess && <div className={styles.tableLoading}>{t('has_error')}</div>}
				</td>
			</tr>
		),
		[isLoading, isSuccess, myUnions, error, t]
	)

	return (
		<table className={styles.table}>
			<thead>
				<tr>{tableHeader}</tr>
			</thead>
			<tbody>{isLoading || myUnions.length < 1 ? tableLoading : tableBody}</tbody>
		</table>
	)
}
