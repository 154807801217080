import { array, object, string } from 'yup'
import { transformStringDateFormatToDate } from '@/shared/lib/helpers/transformStringDateFormatToDate.js'

export const ProfUnionsValidationSchema = object().shape({
	bin: string().when('typeCode', {
		is: (typeCode) => typeCode !== 'primary',
		then: string()
			.required({ key: 'required' })
			.matches(/^[0-9]+$/, 'contracts_tab.not_correct_bin')
			.min(12, { key: 'min_12_length' }),
		otherwise: string().nullable(),
	}),
	unionName: string().required('required'),
	dateCreate: transformStringDateFormatToDate().required({ key: 'required' }),
	typeCode: string().required('required'),
	chiefIin: string().required('required'),
	chiefFio: string().required('required'),
	industryCode: string().when('typeCode', {
		is: (typeCode) => typeCode === 'industry',
		then: string().required('required'),
		otherwise: string().nullable(),
	}),
	parentBin: string().when('typeCode', {
		is: (typeCode) => typeCode !== 'industry',
		then: string().required('required'),
		otherwise: string().nullable(),
	}),
	parentName: string().when('typeCode', {
		is: (typeCode) => typeCode !== 'industry',
		then: string().required('required'),
		otherwise: string().nullable(),
	}),
	employerBin: string().when('typeCode', {
		is: (typeCode) => typeCode !== 'industry',
		then: string().required('required'),
		otherwise: string().nullable(),
	}),
	employerName: string().when('typeCode', {
		is: (typeCode) => typeCode !== 'industry',
		then: string().required('required'),
		otherwise: string().nullable(),
	}),
})

export const AddMembersValidationSchema = object().shape({
	members: array()
		.of(
			object().shape({
				iin: string()
					.required({ key: 'required' })
					.matches(/^[0-9]+$/, { key: 'contracts_tab.not_correct_bin' })
					.min(12, { key: 'min_12_length' }),
				fio: string().required({ key: 'required' }),
			})
		)
		.required(),
})
