import React, { useMemo, Children, isValidElement, useContext } from 'react'
import { Context } from './Context'
import { TableBody } from './TableBody'

export const RenderTableColumns = ({ children }) => {
	const parsedColumns = useMemo(
		() =>
			Children.toArray(children).reduce(
				(acc, child) => (isValidElement(child) ? [...acc, { ...child.props, key: child.key }] : acc),
				[]
			),
		[children]
	)

	const { emptyPlaceholder } = useContext(Context)

	return (
		<>
			<thead>
				<tr>
					{parsedColumns?.map((column, index) => (
						<th key={`heading-${index}`} className={column.headingClassName}>
							{column.heading}
						</th>
					))}
				</tr>
			</thead>
			<TableBody>
				{(rowData, index) => (
					<tr key={`rowData-${index}`}>
						{parsedColumns?.map((column, index) => {
							const data = rowData[column.dataKey]
							return (
								<td key={`cell-${index}`} className={column.cellClassName}>
									{column.renderCell ? column.renderCell(data, rowData) : data || emptyPlaceholder}
								</td>
							)
						})}
					</tr>
				)}
			</TableBody>
		</>
	)
}
