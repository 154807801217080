import React, { useEffect, useState } from 'react'
import { Field as FormikField, connect } from 'formik'
import { Field } from '@/ui'
import { useTranslation } from 'react-i18next'
import styles from '@/entity/contracts/ui/form/ContractForm.module.scss'

export const ContractDuration = connect(
	({ nonresidentAttractingBasis = [], formik: { setFieldValue, values  }, nonresidentEducation, isFormDirty }) => {
		const { t } = useTranslation()
		const [withPermission, setWithPermission] = useState(false)
		const [childsValue1, setChildsValue1] = useState([])
		const [childsValue2, setChildsValue2] = useState([])
		const [loadCalendar, setLoadCalendar] = useState(true)
		useEffect(() => {
			const childs = nonresidentEducation.filter(
				(item) =>
					item.parentId === nonresidentEducation.find((i) => i.code === values.foreignLaborInfo.codes.value1)?.id
			)
			setChildsValue1(childs)
			if (childs.findIndex((c) => c.code === values.foreignLaborInfo.codes.value2) === -1) {
				setFieldValue('foreignLaborInfo.codes.value2', null)
				setFieldValue('foreignLaborInfo.codes.value3', null)
			}
			if (!childs.length) {
				setFieldValue('foreignLaborInfo.dnonResidentEducationCode', values.foreignLaborInfo.codes.value1)
			}
		}, [values.foreignLaborInfo.codes.value1])
		useEffect(() => {
			if (!values.foreignLaborInfo.codes.value2) {
				setChildsValue2([])
				setFieldValue('foreignLaborInfo.codes.value3', null)
				return
			}

			const childs = nonresidentEducation.filter(
				(item) =>
					item.parentId === nonresidentEducation.find((i) => i.code === values.foreignLaborInfo.codes.value2)?.id
			)
			setChildsValue2(childs)
			if (childs.findIndex((c) => c.code === values.foreignLaborInfo.codes.value3) === -1) {
				setFieldValue('foreignLaborInfo.codes.value3', null)
			}
			if (!childs.length) {
				setFieldValue('foreignLaborInfo.dnonResidentEducationCode', values.foreignLaborInfo.codes.value2)
			}
		}, [values.foreignLaborInfo.codes.value2])
		useEffect(() => {
			if (values.foreignLaborInfo.codes.value3) {
				setFieldValue('foreignLaborInfo.dnonResidentEducationCode', values.foreignLaborInfo.codes.value3)
			}
		}, [values.foreignLaborInfo.codes.value3])
		useEffect(() => {
			if (values.foreignLaborInfo.dnonResidentAttractingBasisCode) {
				const basis = nonresidentAttractingBasis.find(
					(item) => item.code === values.foreignLaborInfo.dnonResidentAttractingBasisCode
				)
				if (basis.parentId !== 1 && values.foreignLaborInfo.isMigration === 0) {
					setFieldValue('foreignLaborInfo.nonResidentPermitNumber', null)
					setFieldValue('foreignLaborInfo.nonResidentPermitDate', null)
				}
				setWithPermission(basis.parentId === 1)
			}
		}, [values.foreignLaborInfo.dnonResidentAttractingBasisCode])
		useEffect(() => {
			if (values.foreignLaborInfo.isMigration === 1) {
				setLoadCalendar(false)
				setTimeout(() => {
					setLoadCalendar(true)
				}, 100)
			}
		}, [values.foreignLaborInfo.nonResidentPermitDate, values.foreignLaborInfo.isMigration])

		return (
			<>
				<FormikField name={'foreignLaborInfo.dnonResidentAttractingBasisCode'}>
					{({ field, meta: { touched, error } }) => (
						<Field
							type="text"
							fieldType="selectTree"
							label={t('contractDuration.base')}
							isRequired
							placeholder={t('choose_from_handbook')}
							options={nonresidentAttractingBasis}
							error={(touched || isFormDirty) && error}
							hint={t('contractDuration.hint.base')}
							{...field}
						/>
					)}
				</FormikField>
				<div className={`${styles.fields} ${styles.threeFields}`}>
					<FormikField name={'foreignLaborInfo.nonResidentPermitNumber'}>
						{({ field, meta: { touched, error } }) => (
							<Field
								type="text"
								label={t('contractDuration.permit_number')}
								placeholder={t('contractDuration.permit_number_placeholder')}
								isRequired={withPermission}
								{...field}
								hint={t('contractDuration.hint.permit_number')}
								disabled={
									!withPermission ||
									!values.foreignLaborInfo.dnonResidentAttractingBasisCode ||
									values.foreignLaborInfo.isMigration === 1
								}
								error={(touched || isFormDirty) && error}
							/>
						)}
					</FormikField>
					{loadCalendar ? (
						<FormikField name={'foreignLaborInfo.nonResidentPermitDate'}>
							{({ field, meta: { touched, error } }) => (
								<Field
									fieldType="datePicker"
									label={t('contractDuration.dateOfPermit')}
									isRequired={withPermission}
									placeholder={t('default_date_value')}
									error={(touched || isFormDirty) && error}
									hint={t('contractDuration.hint.dateOfPermit')}
									disabled={
										!withPermission ||
										!values.foreignLaborInfo.dnonResidentAttractingBasisCode ||
										values.foreignLaborInfo.isMigration === 1
									}
									{...field}
								/>
							)}
						</FormikField>
					) : null}
				</div>
				<FormikField name={'foreignLaborInfo.codes.value1'}>
					{({ field, meta: { touched, error } }) => (
						<Field
							type="text"
							fieldType="select"
							label={t('contractDuration.education')}
							isRequired
							placeholder={t('choose_from_handbook')}
							options={nonresidentEducation.filter((item) => item.parentId === null)}
							error={(touched || isFormDirty) && error}
							hint={t('contractDuration.hint.education')}
							{...field}
						/>
					)}
				</FormikField>
				<FormikField name={'foreignLaborInfo.codes.value2'}>
					{({ field, meta: { touched, error } }) => (
						<Field
							type="text"
							fieldType="select"
							label={t('contractDuration.speciality')}
							isRequired={childsValue1.length}
							placeholder={t('choose_from_handbook')}
							options={childsValue1}
							error={(touched || isFormDirty) && error}
							disabled={!values.foreignLaborInfo.codes.value1 || !childsValue1.length}
							hint={t('contractDuration.hint.speciality')}
							{...field}
						/>
					)}
				</FormikField>
				<FormikField name={'foreignLaborInfo.codes.value3'}>
					{({ field, meta: { touched, error } }) => (
						<Field
							type="text"
							fieldType="select"
							label={t('contractDuration.qualification')}
							isRequired={childsValue2.length}
							placeholder={t('choose_from_handbook')}
							options={childsValue2}
							error={(touched || isFormDirty) && error}
							disabled={!values.foreignLaborInfo.codes.value2 || !childsValue2.length}
							hint={t('contractDuration.hint.qualification')}
							{...field}
						/>
					)}
				</FormikField>
			</>
		)
	}
)
