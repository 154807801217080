import { useTranslation } from 'react-i18next'
import { getCurrentLanguage } from '@/i18next'
import styles from './TradeUnionsTable.module.scss'
import React, { useCallback, useMemo, useState } from 'react'
import { headCells } from './tradeUnionsTable.headings'
import DropDownMenu from '@/components/DropdownMenu/DropdownMenu'
import { useDispatch, useSelector } from 'react-redux'
import { contractActionsSelectors } from '@features/contracts/model'
import { profUnionsThunks } from '@/entity'
import onActionMenuClick from '@features/profUnions/table/onActionMenuClick'
import { colors, status } from '@features/profUnions/table/utils'

export const TradeUnionsTable = ({ onToggleAction, profUnionsList, isEmployee }) => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	const dispatch = useDispatch()
	const options = useSelector(contractActionsSelectors.options)
	const { unionType } = options
	const [actionMenuItems, setActionMenuItems] = useState([])

	const getRequest = useCallback((externalId) => dispatch(profUnionsThunks.getUnionTrade(externalId)), [dispatch])
	const approveTradeUnion = useCallback(
		(externalId) => dispatch(profUnionsThunks.approveTradeUnion(externalId)),
		[dispatch]
	)

	const onActionMenuChoose = (mode, row) => {
		switch (mode) {
			case 'view':
				getRequest(row.externalId)
				break
			default:
				// Если mode равен 'approve', вызвать approveTradeUnion
				approveTradeUnion(row.externalId)
		}
		onToggleAction(mode, true)
	}

	const fastView = (row) => {
		onActionMenuChoose('view', row)
	}

	const tableHeader = useMemo(
		() => (
			<>
				{headCells.map((head, index) => (
					<th key={index + 'thead-th'}>{t(head.label)}</th>
				))}
			</>
		),
		[t]
	)

	const getTableRow = useCallback(
		(row, index) => {
			return (
				<tr key={index} onClick={() => fastView(row)}>
					{headCells.map((cell) => {
						switch (cell.id) {
							case 'dateCreate':
								return <td key={cell.id}>{row.dateCreate}</td>
							case 'typeCode': {
								const typeObject = unionType.find((unionObject) => unionObject.code === row.typeCode)
								let typeName = '-'
								if (typeObject) {
									typeName = typeObject[lang] || '-'
								}
								return <td key={cell.id}>{typeName}</td>
							}
							case 'bin':
								return <td key={cell.id}>{row.bin || '-'}</td>
							case 'union_name':
								return <td key={cell.id}>{row.unionName || '-'}</td>
							case 'count_manager':
								return <td key={cell.id}>{row.managers || 1}</td>
							case 'status':
								return (
									<td key={cell.id}>
										<span style={{ color: colors[row.status] }}>{t(status[row.status]) || '-'}</span>
									</td>
								)
							case 'actions':
								return (
									<td key={cell.id} onClick={(e) => onActionMenuClick(row, e, setActionMenuItems, t, isEmployee)}>
										<DropDownMenu
											className={styles.minWidth}
											title={t('select_pls')}
											items={actionMenuItems}
											onItemClick={(mode) => onActionMenuChoose(mode, row)}
										/>
									</td>
								)
							default:
								return null
						}
					})}
				</tr>
			)
		},
		[lang, t, unionType, fastView, colors, actionMenuItems, onActionMenuChoose]
	)

	const tableBody = useMemo(
		() => profUnionsList?.map((row, index) => getTableRow(row, index)),
		[profUnionsList, getTableRow]
	)

	return (
		<table className={styles.table}>
			<thead>
				<tr>{tableHeader}</tr>
			</thead>
			<tbody>{tableBody}</tbody>
		</table>
	)
}
