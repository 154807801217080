import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { contractsSelectors, contractsThunks, contractsActions } from '@/entity/contracts'
import { Book, EBook } from '@app/images'
import { CompareArrowsIcon } from '@app/icons'
import { handbookThunks } from '@/entity/handbook'
import { userActions, userSelectors, userThunks } from '@/entity/user'
import { Button } from '@/ui'
import { Form } from './form'
import { ChangeBinForm } from './changeBinForm'
import { contractActionsThunks, contractActionsSelectors } from '../model'
import styles from './RegistrationEmployerContract.module.scss'
import { useToggle, useDigitalSign } from '@/shared'
import { employerProfileSelectors } from '@features/employerProfile/model'
import { LoadingBlocker } from '@/components/Loader'
import { initialContract } from '@/entity/contracts/model/reducer'

export const RegistrationEmployerContract = ({ fetchData, filters, setFilters, page, pageSize }) => {
	const dispatch = useDispatch()
	const [isOpenedForm, toggleOpenForm] = useToggle(false)
	const [isElectronic, setIsElectronic] = useState(false)
	const [isOpenedChangeBinForm, toggleOpenChangeBinForm] = useToggle(false)
	const worker = useSelector(userSelectors.userByIin)
	const editContractXML = useSelector(contractsSelectors.editXML)
	const isLoading = useSelector(contractsSelectors.contractIsLoading)
	const isSuccess = useSelector(contractsSelectors.contractIsSuccess)
	const activeCompany = useSelector(employerProfileSelectors.activeCompany)
	const contract = {
		...initialContract,
		bin: activeCompany.bin,
		companyName: activeCompany.companyName,
		dcountryCode: '398',
		externalId: null,
	}
	const [isEsutd, setIsEsutd] = useState(false)
	const [formData, setFormData] = useState(null)
	const activeRole = useSelector(userSelectors.activeRole)
	const { successPayload, onSign, failedPayload, onClearPayload } = useDigitalSign()
	const { t } = useTranslation()
	const options = useSelector(contractActionsSelectors.options)

	const onClear = () => {
		onClearPayload()
		dispatch(contractsActions.setEditXML(null))
		dispatch(userActions.setUserByBin(null))
		dispatch(contractsActions.setContractSuccess(false))
	}

	useEffect(() => isOpenedForm && dispatch(contractActionsThunks.updateInitialContract()), [isOpenedForm, dispatch])
	useEffect(() => {
		if (successPayload) {
			dispatch(contractsThunks.sendSignedContract(successPayload, true, formData))
			onClearPayload()
		}
	}, [successPayload])
	useEffect(() => {
		failedPayload && onClear()
	}, [failedPayload])
	useEffect(() => editContractXML && onSign(editContractXML), [editContractXML])
	useEffect(() => {
		failedPayload && onClear()
	}, [failedPayload])
	useEffect(() => {
		if (isSuccess) {
			toggleOpenForm(false)
			onClear()
		}
		return onClear()
	}, [isSuccess])

	const onSubmit = (data) => {
		setFormData(data)
		if (isEsutd) {
			dispatch(contractsThunks.generateEditXML(data))
		} else {
			dispatch(contractsThunks.createLocalContract(data))
		}
	}
	const onFindWorker = (iin) => dispatch(userThunks.getUserByIin(iin))
	const onGetHandBookOptions = (label, code) => {
		label === 'district' && dispatch(handbookThunks.getRegions({ code }))
		label === 'region' && dispatch(handbookThunks.getPopulatedAreas({ code }))
	}

	const handleopenForm = (isElectronic) => {
		setIsElectronic(isElectronic)
		toggleOpenForm()
	}
	const Close = () => {
		toggleOpenForm(false)
		setIsElectronic(false)
	}
	const CloseAndUpdateFilter = () => {
		toggleOpenForm(false)
		setFilters({ ...filters, dcontractStatusCode: '6' })
		fetchData({ ...filters, dcontractStatusCode: '6' })
	}

	return (
		<>
			{activeRole === 'ROLE_EMPLOYER' && (
				<div className={styles.actions__wrapper}>
					<div className={styles.actions}>
						<Button icon={Book} iconSize={'big'} onClick={toggleOpenForm}>
							{t('contracts_tab.registration_employer_contract')}
						</Button>
					</div>
					<div className={styles.actions}>
						<Button icon={EBook} iconSize={'big'} onClick={handleopenForm.bind(null, true)}>
							{t('contracts_tab.registration_employer_electronic_contract')}
						</Button>
					</div>
					{!activeCompany.flEmployer ? (
						<div className={styles.actions}>
							<Button icon={<CompareArrowsIcon />} iconSize={'big'} onClick={toggleOpenChangeBinForm}>
								{t('contracts_tab.change_bin_because_of_reorganization')}
							</Button>
						</div>
					) : null}
				</div>
			)}
			{isLoading ? <LoadingBlocker /> : null}
			{isOpenedForm ? (
				<Form
					initialValues={contract}
					onCancel={Close}
					onSubmit={onSubmit}
					onFindWorker={onFindWorker}
					workerData={worker}
					isElectronic={isElectronic}
					options={options}
					onGetHandBookOptions={onGetHandBookOptions}
					setIsEsutd={setIsEsutd}
					CloseAndUpdateFilter={CloseAndUpdateFilter}
					fetchData={fetchData}
					filters={filters}
					page={page}
					pageSize={pageSize}
				/>
			) : null}
			{isOpenedChangeBinForm && (
				<ChangeBinForm
					initialValues={contract}
					onCancel={toggleOpenChangeBinForm}
					onSubmit={onSubmit}
					onFindWorker={onFindWorker}
					workerData={worker}
					options={options}
					onGetHandBookOptions={onGetHandBookOptions}
					setIsEsutd={setIsEsutd}
				/>
			)}
		</>
	)
}
