import React, { useMemo, useRef } from 'react'
import { Arrow } from '@app/icons'
import { useToggle, useClickOutside } from '@/shared'
import { Options } from '@/ui/atoms'
import styles from './Select.module.scss'
import { InputWithIcon } from '@/ui'
import { getCurrentLanguage } from '@/i18next'
import { useTranslation } from 'react-i18next'

export const Select = ({
	options = [],
	value,
	placeholder,
	showItems = 5,
	onChange,
	onBlur,
	required = false,
	className = '',
	inputClasses = '',
	iconClasses = '',
	optionsStyles,
	...props
}) => {
	const { t } = useTranslation()
	const [isOpenedOptions, toggleOpenedOptions] = useToggle(false)
	const currentLanguage = getCurrentLanguage()
	const selectRef = useRef(null)
	const activeItem = useMemo(() => {
		if (!value) return null
		const val = options.find(({ code }) => code === value)
		if (val) {
			const lang = currentLanguage === 'rus' ? 'nameRu' : 'nameKz'
			return val[currentLanguage] || val[lang] || null
		}
		return null
	}, [value, options, currentLanguage])

	const onSelect = (code) => {
		onChange(code)
		toggleOpenedOptions()
	}

	const onClickOutside = () => toggleOpenedOptions()

	useClickOutside(selectRef, isOpenedOptions, onClickOutside)

	const currentSelectedElement = options.find((item) => item.code == value)

	return (
		<div className={`${styles.select} ${className}`} ref={selectRef}>
			<InputWithIcon
				value={activeItem}
				readOnly
				placeholder={placeholder || t('select_from_list')}
				onClick={() => toggleOpenedOptions()}
				iconSide="right"
				required={required}
				inputClasses={inputClasses}
				iconClasses={iconClasses}
				icon={
					<Arrow
						styles={{ fill: '#AAB8D1', transform: `rotate(${isOpenedOptions ? '90deg' : '-90deg'})`, width: '10px' }}
					/>
				}
				valueIcon={currentSelectedElement?.icon}
				{...props}
			/>
			{isOpenedOptions && (
				<Options
					onSelect={onSelect}
					options={options}
					showItems={showItems}
					onBlur={onBlur}
					optionsStyles={optionsStyles}
				/>
			)}
		</div>
	)
}
