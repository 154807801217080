import { connect, Field as FormikField } from 'formik'
import { useTranslation } from 'react-i18next'
import { formFieldNames } from '@/entity'
import React, { useMemo } from 'react'
import { Field } from '@/ui'

const citiesInRegionsCodes = ['750000000', '710000000', '790000000']

export const LocalityFieldContract = connect(
	({ options = [], formik: { values }, isFormDirty, validateCountry = true }) => {
		const { t } = useTranslation()

		const isRequired =
			values[formFieldNames.countryWork] === '398' &&
			!citiesInRegionsCodes.includes(values[formFieldNames.districtWork]) &&
			values[formFieldNames.remoteWork] !== 1

		const allOptions = useMemo(
			() => (values[formFieldNames.regionWork] && values[formFieldNames.districtWork] ? options : []),
			[options, values]
		)

		return (
			<FormikField name={formFieldNames.locality}>
				{({ field, meta: { touched, error } }) => (
					<Field
						type="text"
						fieldType="select"
						isRequired={isRequired}
						label={t('contracts_tab.populated_area')}
						placeholder={t('choose_from_handbook')}
						options={allOptions}
						error={(touched || isFormDirty) && error}
						disabled={
							(validateCountry && values[formFieldNames.countryWork] !== '398') || // Не активно, если выбрана страна не Казахстан
							options?.length === 0 ||
							citiesInRegionsCodes.includes(values[formFieldNames.districtWork])
						}
						{...field}
					/>
				)}
			</FormikField>
		)
	}
)
