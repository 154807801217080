import * as types from './types'

export const actions = {
	toggleLoading: (payload) => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	toggleSuccess: (payload) => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
	toggleError: (payload) => ({
		type: types.TOGGLE_ERROR,
		payload,
	}),
	setContract: (payload) => ({
		type: types.SET_CONTRACT,
		payload,
	}),
	setContractLoading: (payload) => ({
		type: types.SET_CONTRACT_LOADING,
		payload,
	}),
	setContractSuccess: (payload) => ({
		type: types.SET_CONTRACT_SUCCESS,
		payload,
	}),
	setContracts: (payload) => ({
		type: types.SET_CONTRACTS,
		payload,
	}),
	setCreateContractXML: (payload) => ({
		type: types.SET_CREATE_CONTRACT_XML,
		payload,
	}),
	setTerminateXML: (payload) => ({
		type: types.SET_TERMINATE_XML,
		payload,
	}),
	setTerminationIsLoading: (payload) => ({
		type: types.SET_TERMINATION_IS_LOADING,
		payload,
	}),
	setTerminationIsSuccess: (payload) => ({
		type: types.SET_TERMINATION_IS_SUCCESS,
		payload,
	}),
	resetTermination: () => ({
		type: types.RESET_TERMINATION,
	}),
	setRemoveXML: (payload) => ({
		type: types.SET_REMOVE_XML,
		payload,
	}),
	setRecoverXML: (payload) => ({
		type: types.SET_RECOVER_XML,
		payload,
	}),
	updateContractAfterRecover: (payload) => ({
		type: types.UPDATE_CONTRACT_AFTER_RECOVER,
		payload,
	}),
	updateContractAfterUpdateIin: (payload) => ({
		type: types.UPDATE_CONTRACT_AFTER_UPDATE_IIN,
		payload,
	}),
	setEditXML: (payload) => ({
		type: types.SET_EDIT_XML,
		payload,
	}),
	getDataByBinStart: (payload) => ({
		type: types.GET_DATA_BY_BIN_START,
		payload,
	}),
	getXmlToReorganizeLoading: (payload) => ({
		type: types.GET_XML_TO_REORGANIZE_LOADING,
		payload,
	}),
	clearXmlToReorganize: (payload) => ({
		type: types.CLEAR_XML_TO_REORGANIZE,
		payload,
	}),
	sendSignedXml: (payload) => ({
		type: types.SEND_SIGNED_XML_REORGANIZE,
		payload,
	}),
	setReorganizeSuccess: (payload) => ({
		type: types.SET_REORGANIZE_SUCCESS,
		payload,
	}),
	reorganizeError: (payload) => ({
		type: types.REORGANIZE_ERROR,
		payload,
	}),
	getDataByBin: (payload) => ({
		type: types.GET_DATA_BY_BIN,
		payload,
	}),
	getDataByBinError: (payload) => ({
		type: types.GET_DATA_BY_BIN_ERROR,
		payload,
	}),
	clearDataByBin: (payload) => ({
		type: types.CLEAR_DATA_BY_BIN,
		payload,
	}),
	getEmployersListToChange: (payload) => ({
		type: types.GET_EMPLOYERS_LIST_TO_CHANGE,
		payload,
	}),
	getReorganizeXml: (payload) => ({
		type: types.GET_REORGANIZE_XML,
		payload,
	}),
	setInitialContract: () => ({
		type: types.SET_INITIAL_CONTRACT,
	}),
	setContractFields: (payload) => ({
		type: types.SET_CONTRACT_FIELDS,
		payload,
	}),
	setTerminationReasons: (payload) => ({
		type: types.SET_TERMINATION_REASONS,
		payload,
	}),
	getFLData: (payload) => ({
		type: types.GET_FL_DATA,
		payload,
	}),
	getXmlForUpdateIin: (payload) => ({
		type: types.GET_XML_FOR_UPDATE_IIN,
		payload,
	}),
	sendUpdateIinXml: (payload) => ({
		type: types.SEND_XML_FOR_UPDATE_IIN,
		payload,
	}),
	setErrorUpdateIin: (payload) => ({
		type: types.SET_ERROR_UPDATE_IIN,
		payload,
	}),
	resetUpdateIinXml: () => ({ type: types.RESET_UPDATE_IIN }),
	setAdditionalContractXml: (payload) => ({
		type: types.SET_ADDITIONAL_CONTRACT_XML,
		payload,
	}),
	setAdditionalContractIsLoading: (payload) => ({
		type: types.SET_ADDITIONAL_CONTRACT_IS_LOADING,
		payload,
	}),
	setAdditionalContractSubmitted: () => ({
		type: types.SET_ADDITIONAL_CONTRACT_SUBMITTED,
	}),
	resetAdditionalContract: () => ({
		type: types.RESET_ADDITIONAL_CONTRACT,
	}),
	setAdditionalContractId: (payload) => ({
		type: types.SET_ADDITIONAL_CONTRACT,
		payload,
	}),
	setContractCardSubmitted: (payload) => ({
		type: types.SET_CONTRACT_CARD_SUBMITTED,
		payload,
	}),
	setEditSuccess: (payload) => ({
		type: types.SET_EDIT_SUCCESS,
		payload,
	}),
	resetEditing: () => ({
		type: types.RESET_EDITING,
	}),
	setParams: (payload) => ({
		type: types.SET_PARAMS,
		payload,
	}),
	setSocialLeaveIsLoading: (payload) => ({
		type: types.SET_SOCIAL_LEAVE_IS_LOADING,
		payload,
	}),
	setSocialLeaveIsSuccess: (payload) => ({
		type: types.SET_SOCIAL_LEAVE_IS_SUCCESS,
		payload,
	}),
	setSocialLeaveXML: (payload) => ({
		type: types.SET_SOCIAL_LEAVE_XML,
		payload,
	}),
	resetSocialLeave: () => ({
		type: types.RESET_SOCIAL_LEAVE,
	}),
	setProlongationIsSuccess: (payload) => ({
		type: types.SET_PROLONGATION_IS_SUCCESS,
		payload,
	}),
	setProlongationIsLoading: (payload) => ({
		type: types.SET_PROLONGATION_IS_LOADING,
		payload,
	}),
	setProlongationXML: (payload) => ({
		type: types.SET_PROLONGATION_XML,
		payload,
	}),
	resetProlongation: () => ({
		type: types.RESET_PROLONGATION,
	}),
	setSelectedContracts: (payload) => ({
		type: types.SET_SELECTED_CONTRACTS_STATE,
		payload,
	}),
	// PACKAGE LOADING
	getXmlToSignSomeLoading: () => ({ type: types.GET_XML_TO_SIGN_SOME_LOADING }),
	getXmlToSignSome: (payload) => ({
		type: types.GET_XML_TO_SIGN_SOME,
		payload,
	}),
	getXmlToSignSomeError: (payload) => ({
		type: types.GET_XML_TO_SIGN_SOME_ERROR,
		payload,
	}),
	sendSignedXmlPackageSome: (payload) => ({
		type: types.SEND_SIGNED_XML_PACKAGE_SOME,
		payload,
	}),
	deleteContracts: (payload) => ({
		type: types.DELETE_PACKAGE_SOME,
		payload,
	}),
	resetPackage: (payload) => ({
		type: types.RESET_PACKAGE,
		payload,
	}),

	setContractError: (payload) => ({
		type: types.SET_CONTRACT_ERROR,
		payload,
	}),
	resetContract: () => ({
		type: types.RESET_CONTRACT,
	}),
	setIsEdit: (payload) => ({
		type: types.SET_IS_EDIT,
		payload,
	}),

	// ЭТД
	etdToggleLoading: (payload) => ({
		type: types.ETD_TOGGLE_LOADING,
		payload,
	}),
	etdToggleSuccess: (payload) => ({
		type: types.ETD_TOGGLE_SUCCESS,
		payload,
	}),
	setEtdFilter: (payload) => ({
		type: types.ETD_SET_FILTER,
		payload,
	}),
	setEtdParams: (payload) => ({
		type: types.ETD_SET_PARAMS,
		payload,
	}),
	getEtdListStart: (payload) => ({
		type: types.ETD_GET_LIST_START,
		payload,
	}),
	getEtdListEnd: (payload) => ({
		type: types.ETD_GET_LIST_END,
		payload,
	}),
	getEtdListError: (payload) => ({
		type: types.ETD_GET_LIST_ERROR,
		payload,
	}),
	getCurrentEtd: (payload) => ({
		type: types.ETD_GET_CURRENT_ETD,
		payload,
	}),
	setCurrentEtd: (payload) => ({
		type: types.ETD_SET_CURRENT_ETD,
		payload,
	}),
	setCurrentEtdEdit: (payload) => ({
		type: types.ETD_SET_CURRENT_ETD_EDIT,
		payload,
	}),
	saveProject: (payload) => ({
		type: types.ETD_SAVE_PROJECT,
		payload,
	}),
	sendToEmployee: (payload) => ({
		type: types.ETD_SEND_TO_EMPLOYEE,
		payload,
	}),
	declineEmployee: (payload) => ({
		type: types.ETD_DECLINE_EMPLOYEE,
		payload,
	}),
	sendForImprovementEmployee: (payload) => ({
		type: types.ETD_SEND_FOR_IMPROVEMENT_EMPLOYEE,
		payload,
	}),
	declineEmployer: (payload) => ({
		type: types.ETD_DECLINE_EMPLOYER,
		payload,
	}),
	deleteEtd: (payload) => ({
		type: types.DELETE_ETD,
		payload,
	}),
	setStatusSignEtd: (payload) => ({
		type: types.ETD_STATUS_SIGN,
		payload,
	}),
	setImageEtd: (payload) => ({
		type: types.ETD_IMAGE,
		payload,
	}),
	setImageKey: (payload) => ({
		type: types.ETD_IMAGE_KEY,
		payload,
	}),
	setErrorEtd: (payload) => ({
		type: types.ETD_ERROR,
		payload,
	}),
	resetEtdQR: () => ({
		type: types.RESET_ETD_QR,
	}),
}
