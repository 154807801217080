import React from 'react'
import { useTranslation } from 'react-i18next'
import { Title } from '@/ui/atoms'
import styles from './dataGridTable.module.scss'
import { Box } from '@mui/system'
import { Tooltip } from 'antd'
import { Info } from '@app/icons'

export const DataGridTable = ({
	title,
	information,
	subTitle,
	hideTitle,
	hideSubTitle,
	titleClass = '',
	subTitleAction,
	subTitleClass = '',
	rowClass = '',
	dataClass = '',
	hideCustomTitle,
	customTitle,
}) => {
	const { t } = useTranslation()
	return (
		<div>
			{!hideTitle && <Title>{t(title)}</Title>}
			<Box display="flex" alignItems="baseline">
				{!hideSubTitle && <p className={`${styles.subTitle} ${subTitleClass}`}>{subTitle}:</p>}
				{subTitleAction && <div className={styles.action}>{subTitleAction}</div>}
			</Box>
			<Box display="flex" alignItems="baseline">
				{!hideCustomTitle && <p className={`${styles.customClass} ${subTitleClass}`}>{customTitle}</p>}
			</Box>
			{information.map(({ title, data, tooltip, fullWidth }, index) => (
				<div className={`${styles.row} ${rowClass} `} key={`data-row-${index}`}>
					{fullWidth ? (
						<p className={`${titleClass} ${fullWidth ? styles.fullWidth : ''}`}>{t(fullWidth)}</p>
					) : (
						<>
							{title && tooltip ? (
								<p className={titleClass}>
									{t(title)}:{' '}
									<Tooltip
										placement="right"
										color="white"
										overlayInnerStyle={{ color: '#5B6880', whiteSpace: 'pre', width: '450px' }}
										title={tooltip}
									>
										<span>
											<Info className={styles.hintIcon} />
										</span>
									</Tooltip>
								</p>
							) : (
								<p className={titleClass}>{t(title)}:</p>
							)}
							<p className={`${styles.dataCell} ${dataClass}`}>{data || ''}</p>
						</>
					)}
				</div>
			))}
		</div>
	)
}
