import styles from './Table.module.scss'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LoadingBlocker } from '@/components/Loader'
import { headCells } from './table.headings'
import { SocialModal } from '../SocialModal/SocialModal'
import { listSSP } from '@/shared/api/rest/personalAffairs/listSSP'
import { HrPagination } from '@/components/Pagination'
import { sspDataForPerson } from '@/shared/api/rest/personalAffairs/sspDataForPerson'
import { useSelector } from 'react-redux'
import { employerProfileSelectors } from '@features/employerProfile/model'
import { Button } from '@/ui'
import { message } from 'antd'
import { InfoDevelopment } from '@/components/infoDevelopment'
export const Table = () => {
	const {
		t,
		i18n: { language },
	} = useTranslation()
	const [openModal, setOpenModal] = useState(false)
	const [loading, setLoading] = useState(true)
	const [list, setList] = useState({ content: [] })
	const [modalData, setModalData] = useState({ statusHistories: [] })
	const activeCompany = useSelector(employerProfileSelectors.activeCompany)
	const initialQueryState = {
		page: 0,
		pageSize: 10,
		rowsPerPage: [
			{ size: 10, label: '10' },
			{ size: 30, label: '30' },
			{ size: 50, label: '50' },
			{ size: 100, label: '100' },
		],
	}
	const [queryParams, setQueryParams] = useState(initialQueryState)
	const handleChangePage = (e, page) => setQueryParams({ ...queryParams, page: page - 1 })
	const handleChangeRowsPerPage = (size) => setQueryParams((prev) => ({ ...prev, page: 0, pageSize: size }))
	const fetchData = useCallback(async () => {
		const { page, pageSize } = queryParams
		try {
			setLoading(true)
			const result = await listSSP(activeCompany.bin, page, pageSize)
			if (result) {
				setList(result)
				setLoading(false)
			}
		} catch (error) {
			setList({ content: [] })
		} finally {
			setLoading(false)
		}
	}, [queryParams])

	useEffect(() => {
		fetchData()
	}, [fetchData])

	const getModalData = async (iin) => {
		setLoading(true)
		try {
			setLoading(true)
			const result = await sspDataForPerson(activeCompany.bin, iin)
			if (result) {
				setModalData(result)
				setLoading(false)
			}
		} catch (error) {
			setModalData({ statusHistories: [] })
		} finally {
			setOpenModal(true)
			setLoading(false)
		}
	}
	const getColor = (code) => {
		switch (code) {
			case 'afrorefuse':
			case 'autorefuse_alr_have':
			case 'autorefuse_bmg':
			case 'afrorefuse_regadr':
			case 'autorefuse_noanswer':
			case 'autorefuse_regadr':
			case 'autorefuse':
			case 'end':
				return '#EB5757'
			case 'getDocPhone':
			case 'inComeEhrTrud':
			case 'ENPF_Possibility':
			case 'smsOSNS':
			case 'IBAN_Logic':
			case 'sendFullNotifications':
			case 'getFullNotifications':
			case 'waitKSZH_order':
			case 'ok':
				return '#AAB8D1'
			case 'waitKSZH_waiting':
			case 'waitKSZH_error':
				return '#FFB900'
			case 'in_cbd':
				return '#17B67C'
			default:
				return ''
		}
	}
	const getTableRow = useCallback(
		(row, index) => (
			<tr key={index} onClick={() => getModalData(row.iin)}>
				<td>{row.requestDate || '-'}</td>
				<td>{(language === 'rus' ? row.eventRu : row.eventKz) || '-'}</td>
				<td>{row.iin || '-'}</td>
				<td>{row.fio || '-'}</td>
				<td style={{ color: getColor(row.code) }}>
					{(language === 'rus' ? row.requestStatusRu : row.requestStatusKz) || '-'}
				</td>
			</tr>
		),
		[t]
	)

	const tableBody = useMemo(() => list.content.map((row, index) => getTableRow(row, index)), [list, getTableRow])

	const tableHeader = useMemo(
		() => (
			<>
				{headCells.map((head, index) => (
					<th key={index + 'thead-th'}>{t(head.label)}</th>
				))}
			</>
		),
		[t]
	)

	const handleListSSP = async () => {
		const { page, pageSize } = queryParams
		try {
			setLoading(true)
			const result = await listSSP(activeCompany.bin, page, pageSize, false)
			if (result) setList(result)
			setLoading(false)
		} catch (error) {
			setLoading(false)
			const err = error?.response?.data?.message || 'Попробуйте позже, технические проблемы на стороне сервиса'
			message.error(err ? err : 'Попробуйте позже, технические проблемы на стороне сервиса', 5)
		}
	}

	return (
		<>
			<div className={styles.update}>
				<Button onClick={handleListSSP}>{t('update')}</Button>
				<InfoDevelopment title={'welfare.info'} small={true} child={list.lastUpdateDate} />
			</div>
			<table className={styles.table}>
				<thead>
					<tr>{tableHeader}</tr>
				</thead>
				<tbody>{tableBody}</tbody>
			</table>
			<HrPagination
				id="hr-pagination"
				rowsPerPageitems={queryParams.rowsPerPage}
				rowsPerPage={queryParams.pageSize}
				numberOfElements={list.numberOfElements}
				totalElements={list.totalElements}
				totalPages={list.totalPages}
				handleChangePage={handleChangePage}
				handleChangeRowsPerPage={handleChangeRowsPerPage}
				page={queryParams.page}
			/>
			{openModal && <SocialModal onClose={() => setOpenModal(!openModal)} cardInfo={modalData} />}
			{loading && <LoadingBlocker />}
		</>
	)
}
