import { Button, Title } from '@/ui'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './Docs.module.scss'
import { Edit } from '@app/icons'
import { ViewConfirmDocument } from '@/components/ViewDocuments/ViewConfirmDocument'

export const Docs = ({ tradeUnion, onToggleAction, myUnion }) => {
	const { t } = useTranslation()
	const onEdit = () => {
		onToggleAction('edit', true)
		onToggleAction('view', false)
	}
	const { fileCharter, fileProtocol, fileReference } = tradeUnion
	const files = [fileCharter, fileProtocol, fileReference]
	const data = files.filter((file) => file !== null)
	return (
		<section>
			<div className={styles.docs}>
				<Title>{t('profUnions.docs')}</Title>
				{myUnion ? null : (
					<Button borderColor="green" transparent buttonClassName={styles.button} onClick={onEdit}>
						<Edit className={styles.iconGreen} />
						{t('edit_button')}
					</Button>
				)}
			</div>
			<ViewConfirmDocument data={data} local={true}></ViewConfirmDocument>
		</section>
	)
}
