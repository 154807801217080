import React from 'react'
import { Title } from '@/ui'
import TabsUnstyled from '@mui/base/Tabs'
import TabsList from '@/ui/atoms/tabsList'
import Tab from '@/ui/atoms/tab'
import TabPanel from '@mui/base/TabPanel'
import { Table } from './components/Table/Table'
import { Calendar } from './components/Calendar/Calendar'
import { useTranslation } from 'react-i18next'
const SocialPaymentMainTab = () => {
	const { t } = useTranslation()
	return (
		<>
			<section>
				<Title>{t('payment.title')}</Title>
				<TabsUnstyled defaultValue={0}>
					<TabsList>
						<Tab value={0}>{t('payment.calendarTab')}</Tab>
						<Tab value={1}>{t('payment.paymentTab')}</Tab>
					</TabsList>
					<TabPanel value={0}>
						<Calendar />
					</TabPanel>
					<TabPanel value={1}>
						<Table />
					</TabPanel>
				</TabsUnstyled>
			</section>
		</>
	)
}

export default SocialPaymentMainTab
