import React, { useEffect, useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ContractForm, contractsSelectors, contractsThunks, contractsActions } from '@/entity/contracts'
import { DetailsOfPartiesInformation, EditInformation } from '../../info-table'
import { ModalTemplate, Title } from '@/ui'
import { contractActionsSelectors } from '../../model'
import styles from './Edit.module.scss'
import { handbookThunks } from '@/entity/handbook'
import { useDigitalSign } from '@/shared'
import { LoadingBlocker } from '@/components/Loader'

export const Edit = ({ onToggleAction, forceSubmitEdit, setForceSubmitEdit }) => {
	const dispatch = useDispatch()
	const contract = useSelector(contractsSelectors.contract)
	const isLoading = useSelector(contractsSelectors.contractIsLoading)
	const isSuccess = useSelector(contractsSelectors.contractIsSuccess)
	const options = useSelector(contractActionsSelectors.options)
	const editContractXML = useSelector(contractsSelectors.editXML)
	const { successPayload, onClearPayload, failedPayload } = useDigitalSign()
	const { t } = useTranslation()
	const [isEsutd, setIsEsutd] = useState(false)

	const onClear = () => {
		onClearPayload()
		editContractXML && dispatch(contractsActions.resetEditing())
		dispatch(contractsActions.setContractSuccess(false))
	}

	useEffect(() => {
		failedPayload && onClear()
	}, [failedPayload])
	useEffect(() => successPayload && dispatch(contractsThunks.edit(successPayload)), [successPayload])
	useEffect(() => {
		if (isSuccess) {
			onClear()
			onCancel()
		}
		return () => onClear()
	}, [isSuccess])

	const onSubmit = (data) => {
		dispatch(contractsActions.setIsEdit(true))
		if (isEsutd) {
			dispatch(contractsThunks.generateEditXML(data))
		} else {
			dispatch(contractsThunks.createLocalContract(data, true))
		}
	}

	const onCancel = useCallback(() => {
		dispatch(contractsActions.resetEditing())
		dispatch(contractsActions.setIsEdit(false))
		onToggleAction('edit', false, contract)
	}, [dispatch, onClearPayload, onToggleAction])

	const onGetHandBookOptions = useCallback(
		(label, code) => {
			label === 'district' && dispatch(handbookThunks.getRegions({ code }))
			label === 'region' && dispatch(handbookThunks.getPopulatedAreas({ code }))
		},
		[dispatch]
	)

	useEffect(() => {
		// Судя по всему есть косяк на бэке, не всегда передаются данные в codes
		if (!contract?.foreignLaborInfo) return

		const { foreignLaborInfo: fli } = contract
		const { codes } = fli

		if (!fli.dnonResidentEducationCode || codes.value1) return

		const findOptionByCode = (code) => options.nonresidentEducation.find((it) => it.code === code)
		const findOptionById = (id) => options.nonresidentEducation.find((it) => it.id === id)

		const value = findOptionByCode(fli.dnonResidentEducationCode)
		if (!value?.parentId) {
			codes.value1 = fli.dnonResidentEducationCode
			return
		}

		const value1 = findOptionById(value.parentId)
		if (!value1?.parentId) {
			codes.value2 = value.code
			codes.value1 = value1.code
			return
		}

		const value2 = findOptionById(value1.parentId)
		codes.value3 = value.code
		codes.value2 = value1.code
		codes.value1 = value2?.code
	}, [contract])

	useEffect(() => {
		return () => {
			setForceSubmitEdit(false)
		}
	}, [forceSubmitEdit])

	return (
		<>
			{isLoading ? <LoadingBlocker /> : null}
			{contract.externalId ? (
				<ModalTemplate onClose={onCancel}>
					<div className={`scroll ${styles.wrapper}`}>
						<Title color="black">
							{t('edit_contract', {
								contractNumber: contract?.contractNumber || ' ',
								registerDate: contract?.registerDate || ' ',
							})}
						</Title>
						<EditInformation contract={contract} />
						<Title className={styles.title}>{t('parties_requisites')}</Title>
						<DetailsOfPartiesInformation contract={contract} />
						<ContractForm
							options={options}
							initialValues={contract}
							onSubmit={onSubmit}
							onGetHandBookOptions={onGetHandBookOptions}
							onCancel={onCancel}
							setIsEsutd={setIsEsutd}
							isEdit
							forceSubmitEdit={forceSubmitEdit}
							setForceSubmitEdit={setForceSubmitEdit}
						/>
					</div>
				</ModalTemplate>
			) : (
				<LoadingBlocker />
			)}
		</>
	)
}
