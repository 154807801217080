import { Button as FormButton, ModalTemplate, Title } from '@/ui'
import styles from './Form.module.scss'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, List, ListItemButton, ListItemText } from '@mui/material'
import cn from 'classnames'
import ModalReset from '@/entity/contracts/ui/form/components/NewTemplate/ModalReset'
import IconSave from '@features/contracts/actions/view/IconSave'
import ListItem from '@mui/material/ListItem'
import ModalConfirm from '@/entity/contracts/ui/form/components/NewTemplate/ModalConfirm'
import { datePickerlocaleKz, datePickerlocaleRu } from '@/i18next'
import TableSign from '@features/eCollectiveContracts/registration/view/TableSign/TableSign'
import { TitleContractInfo, TradeUnionDecline } from '@features/eCollectiveContracts/registration/component'
import { useDispatch, useSelector } from 'react-redux'
import { eColContractsSelectors, eColContractsThunks, userSelectors } from '@/entity'
import {
	EmployerSignModal,
	EmployerDecline,
	TradeUnionSignModal,
} from '@features/eCollectiveContracts/registration/component/'
import { Edit } from '@app/icons'
import { RevertModal } from '@features/contracts/actions/view/components'

export const Project = ({
	contract,
	documentState,
	eHtml,
	onCancel,
	handleSendToEmployer,
	currentEcd,
	isTradeUnion,
	onClose,
	isEmployee,
}) => {
	const {
		t,
		i18n: { language },
	} = useTranslation()

	const dispatch = useDispatch()
	const activeRole = useSelector(userSelectors.activeRole)
	const currentEcdEdit = useSelector(eColContractsSelectors.currentEcdEdit)
	const refContainer = useRef(null)
	const refSubContainer = useRef(null)
	const [html, setHtml] = useState(null)
	const [lang, setLang] = useState('rus')
	const [modalResetOpen, setModalResetOpen] = useState(false)
	const [modalConfirmOpen, setModalConfirmOpen] = useState(false)
	const [modalConfirmMessage, setModalConfirmMessage] = useState('')
	//TradeUnion
	const [isTradeUnionOpenSign, setIsTradeUnionOpenSign] = useState(false)
	const [isOpenRevoke, setIsOpenRevoke] = useState(false)
	const [isTradeUnionDeclineOpen, setIsTradeUnionDeclineOpen] = useState(false)
	// EMPLOYER
	const [isEmployerOpenSign, setIsEmployerOpenSign] = useState(false)
	const [isEmployerDeclineOpen, setIsEmployerDeclineOpen] = useState(false)
	const [stateCode, setStateCode] = useState('')

	useEffect(() => {
		if (!!currentEcd) {
			setStateCode(contract.state.code)
		}
	}, [currentEcd])

	useEffect(() => {
		if (eHtml) {
			setHtml(eHtml)
			setLang(eHtml.nameRu ? 'rus' : 'kaz')
		}
	}, [eHtml])

	useEffect(() => {
		if (currentEcd) {
			setHtml(currentEcd.textHtml)
			setLang(currentEcd.clang === 'ru' ? 'rus' : 'kaz')
		}
	}, [currentEcd])

	useEffect(() => {
		if (currentEcdEdit) {
			setHtml(currentEcdEdit)
			setLang(currentEcdEdit.nameRu ? 'rus' : 'kaz')
		}
	}, [currentEcdEdit])

	const handleModalOpen = () => {
		setModalResetOpen(true)
	}

	const handleReset = () => {
		setHtml(null)
		setTimeout(() => setHtml(eHtml))
		setModalResetOpen(false)
	}

	const ChangeColor = (items, color) => {
		items.map((a) => (refSubContainer.current.querySelector('[data-name="' + a.code + '"]').style.color = color))
	}

	const getNumberOfDataName = (code) => {
		const codeNumber = refSubContainer.current
			.querySelector('[data-name="' + code + '"]')
			.parentElement.textContent.split(' ')
			.at(0)
		if (!isNaN(codeNumber.charAt(0))) return (lang === 'rus' ? 'пунктов' : 'тармақтар') + ' (' + codeNumber + ')'
		else return ' (' + (lang === 'rus' ? 'вводной части ЭКД' : 'ЭҰШ кіріспе бөлігі') + ')'
	}

	const getItemText = (item) => {
		if (item?.label === undefined) {
			return lang === 'rus' ? 'указать дату(вводной части ЭКД)' : 'күнін көрсету(ЭҰШ кіріспе бөлігі)'
		}

		if (item.code === 'placeConclusion') {
			return lang === 'rus'
				? 'Вы не заполнили место заключения в электронном коллективном договоре'
				: 'Сіз электронды ұжымдық шартта жасалған орынды толтырмадыңыз'
		}

		return item.label + ' ' + getNumberOfDataName(item.code)
	}

	const handleSaveTemplate = async (isSendToEmployer) => {
		var el = document.createElement('html')
		el.innerHTML = refSubContainer.current.innerHTML

		const elementsCollection = el.getElementsByClassName('field')
		const editTableBlocks = Array.prototype.filter.call(elementsCollection, (element) => element.nodeName !== 'INPUT')
		const dateInputs = Array.prototype.filter.call(elementsCollection, (element) => element.nodeName === 'INPUT')

		const parsedData = []
		const parsedDataNumbers = []
		const dataValidate = {}

		if (editTableBlocks && editTableBlocks?.length) {
			for (let i = 0; i < editTableBlocks.length; i++) {
				const element = editTableBlocks[i]
				if (element.classList.contains('number')) {
					parsedDataNumbers.push({
						code: element.getAttribute('data-name'),
						value: element.innerText,
						required: Boolean(element.getAttribute('required')),
					})
				}
				const findElement = parsedData.find((el) => el.code === element.getAttribute('data-name'))
				if (!findElement) {
					parsedData.push({
						code: element.getAttribute('data-name'),
						value: element.innerText,
						required: Boolean(element.getAttribute('required')),
					})
				}
				dataValidate[element.getAttribute('data-name')] = element.innerText
			}
		}

		if (dateInputs && dateInputs?.length) {
			for (let i = 0; i < dateInputs.length; i++) {
				const element = dateInputs[i]
				parsedData.push({
					code: element.getAttribute('data-name'),
					value: element.value,
					required: Boolean(element.getAttribute('required')),
				})
				dataValidate[element.getAttribute('data-name')] = element.value
			}
		}

		const emptyItems = parsedData.filter((item) => item.value.replace(/\s/g, '') === '' && item.required)

		const wrongParsedDataNumbers = parsedDataNumbers.filter((item) => {
			return /[^0-9.]/g.test(item.value) && item.required
		})

		if (wrongParsedDataNumbers.length !== 0) {
			ChangeColor(parsedDataNumbers, '#17B67C')
			const _wrongParsedDataNumbers = wrongParsedDataNumbers.map((item) => {
				return {
					...item,
					label: editTableBlocks.find((element) => element.getAttribute('data-name') === item.code)?.ariaLabel,
				}
			})
			ChangeColor(_wrongParsedDataNumbers, '#eb5757')
			setModalConfirmOpen(true)
			setModalConfirmMessage(
				lang === 'rus' ? 'Значения полей должны быть числовыми' : 'Өріс мәндері сандық болуы керек'
			)
			setModalConfirmMessage(
				<List sx={{ width: '100%', bgcolor: 'background.paper' }} aria-label="contacts">
					<b>
						{lang === 'rus'
							? 'Значение полей должны быть числовым в электронном коллективном договоре:'
							: 'Электрондық ұжымдық шартта өрістердің мәні сандық болуы керек:'}
					</b>
					<br />
					<br />
					{_wrongParsedDataNumbers.map((item, index) => (
						<ListItem disablePadding key={index}>
							<ListItemButton>
								<ListItemText>{item.label + ' ' + getNumberOfDataName(item.code)}</ListItemText>
							</ListItemButton>
						</ListItem>
					))}
				</List>
			)
			return null
		}

		if (emptyItems.length !== 0) {
			ChangeColor(parsedData, '#17B67C')
			const _emptyItems = emptyItems.map((item) => {
				return {
					...item,
					label: editTableBlocks.find((element) => element.getAttribute('data-name') === item.code)?.ariaLabel,
				}
			})
			ChangeColor(_emptyItems, 'red')
			setModalConfirmOpen(true)
			setModalConfirmMessage(
				<List sx={{ width: '100%', bgcolor: 'background.paper' }} aria-label="contacts">
					<ListItem disablePadding key={'3-default'}>
						<ListItemButton>
							<ListItemText>
								<b>
									{lang === 'rus'
										? 'Вы не заполнили поля в электронном коллективном договоре:'
										: 'Сіз электрондық ұжымдық шартта өрістерді толтырмадыңыз:'}
								</b>
							</ListItemText>
						</ListItemButton>
					</ListItem>
					{_emptyItems.map((item, index) => (
						<ListItem disablePadding key={index}>
							<ListItemButton>
								<ListItemText>{getItemText(item)}</ListItemText>
							</ListItemButton>
						</ListItem>
					))}
				</List>
			)
			return null
		}
		handleSendToEmployer(parsedData, isSendToEmployer)
	}

	$.fn.bootstrapDP.defaults.language = language
	$.fn.bootstrapDP.defaults.defaultViewDate = new Date()
	$.fn.bootstrapDP.defaultViewDate = new Date()
	$.fn.bootstrapDP.dates['rus'] = datePickerlocaleRu
	$.fn.bootstrapDP.dates['kz'] = datePickerlocaleKz

	const handleDecline = () => {
		setIsEmployerDeclineOpen(false)
		setIsTradeUnionDeclineOpen(false)
		onCancel()
		onClose()
	}

	const handleCloseDecline = () => {
		setIsEmployerDeclineOpen(false)
		setIsTradeUnionDeclineOpen(false)
	}

	const changeEditStatus = () => {
		dispatch(eColContractsThunks.getEditHtml({ externalId: contract.externalId }, isEmployee))
	}

	const name = html?.nameRu !== null ? html?.nameRu : html?.nameKz

	const handleRevertTradeUnion = () =>
		dispatch(eColContractsThunks.revokeTradeUnion(contract.externalId, isEmployee, onClose))

	return (
		<ModalTemplate onClose={onCancel}>
			<div className={`scroll ${styles.wrapper}`}>
				{!!contract && !!documentState ? (
					<TitleContractInfo contract={contract} documentState={documentState} t={t} />
				) : (
					<div className={styles.mainInformation}>
						<Title color="black">{t('col_contract.project_title')}</Title>
					</div>
				)}
				<div className={styles.editor__wrapper} ref={refContainer}>
					<div
						dangerouslySetInnerHTML={{
							__html: currentEcdEdit ? name : currentEcd ? html : name,
						}}
						ref={refSubContainer}
						className={styles.editor__subwrapper}
					></div>
				</div>
				{!!currentEcd && stateCode === '6' && (
					<div className={styles.employee__comment__wrapper}>
						<div className={styles.ecd__title}>{t('col_contract.comment_employer')}</div>
						<div className={styles.employee__comment}>{currentEcd.note}</div>
					</div>
				)}
				<div className={isTradeUnion ? styles.buttons__wrapper : styles.buttons__wrapper_employer}>
					{stateCode !== '1' ? (
						<>
							{isTradeUnion ? (
								<>
									{documentState ? (
										<>
											{currentEcdEdit ? (
												<Button
													type="button"
													startIcon={<IconSave />}
													classes={{ root: cn(styles.button, styles.button__save) }}
													sx={{ paddingLeft: '20px !important' }}
													onClick={handleSaveTemplate.bind(null, false)}
												>
													{t('save')}
												</Button>
											) : (
												<Button
													type="button"
													disabled={!['3', '6', '8', '9', '10'].includes(stateCode)}
													startIcon={<Edit fill={'#17B67C'} className={styles.edit__icon} />}
													classes={{
														root: cn(styles.button_etd, styles.button__save),
														disabled: styles.button__disabled,
													}}
													sx={{ paddingLeft: '20px !important' }}
													onClick={changeEditStatus}
												>
													{t('edit_button')}
												</Button>
											)}
										</>
									) : (
										<>
											<Button
												type="button"
												variant="text"
												classes={{ root: cn(styles.button, styles.reset__button) }}
												onClick={handleModalOpen}
											>
												{t('electronic_contract.resert_values')}
											</Button>
											<Button
												type="button"
												startIcon={<IconSave />}
												classes={{ root: cn(styles.button, styles.button__save) }}
												sx={{ paddingLeft: '20px !important' }}
												onClick={handleSaveTemplate.bind(null, false)}
											>
												{t('save')}
											</Button>
										</>
									)}
									<FormButton
										type="submit"
										disabled={!['3', '6', '8', '9', '10', ''].includes(stateCode)}
										onClick={handleSaveTemplate.bind(null, true)}
									>
										{t('col_contract.send_to_sign_employer')}
									</FormButton>
								</>
							) : null}
						</>
					) : null}
				</div>
				<div className={styles.status__wrapper}>
					{!!currentEcd ? <TableSign currentEcd={currentEcd} /> : null}
					{!!currentEcd && stateCode !== '1' ? (
						<Box display="flex" alignItems="center" justifyContent="flex-end" className={styles.buttons__wrapper_sign}>
							{isTradeUnion && stateCode === '4' && (
								<FormButton
									buttonClassName={styles.decline__button}
									onClick={setIsTradeUnionDeclineOpen.bind(null, true)}
								>
									{t('decline')}
								</FormButton>
							)}
							{isTradeUnion && stateCode === '7' && (
								<FormButton buttonClassName={styles.decline__button} onClick={setIsOpenRevoke.bind(null, true)}>
									{t('actions.revoke')}
								</FormButton>
							)}
							{!isTradeUnion && activeRole === 'ROLE_EMPLOYER' && (
								<FormButton
									disabled={stateCode === '4'}
									buttonClassName={styles.decline__button}
									onClick={setIsEmployerDeclineOpen.bind(null, true)}
								>
									{t('decline')}
								</FormButton>
							)}
							{!isTradeUnion && activeRole === 'ROLE_EMPLOYER' && (
								<FormButton disabled={stateCode === '4'} onClick={setIsEmployerOpenSign.bind(null, true)}>
									{t('sign_button')}
								</FormButton>
							)}
							{isTradeUnion && (
								<FormButton disabled={stateCode !== '4'} onClick={setIsTradeUnionOpenSign.bind(null, true)}>
									{t('sign_button')}
								</FormButton>
							)}
							<div className={styles.buttonEnd}>
								<FormButton onClick={onCancel}>{t('close')}</FormButton>
							</div>
						</Box>
					) : (
						<div className={styles.buttonEnd}>
							<FormButton onClick={onCancel}>{t('close')}</FormButton>
						</div>
					)}
				</div>
			</div>
			<ModalReset onClose={() => setModalResetOpen(false)} isOpen={modalResetOpen} onReset={handleReset} />
			<ModalConfirm
				onClose={() => setModalConfirmOpen(false)}
				isOpen={modalConfirmOpen}
				message={modalConfirmMessage}
			/>
			{!!currentEcd ? (
				<>
					{isTradeUnion ? (
						<>
							<RevertModal
								title={'col_contract.revert_title'}
								onClose={setIsOpenRevoke.bind(null, false)}
								onRevert={handleRevertTradeUnion}
								isOpen={isOpenRevoke}
							/>
							<TradeUnionDecline
								title={'col_contract.decline_title_trade_union'}
								isEmployee={isEmployee}
								onClose={handleDecline}
								externalId={contract.externalId}
								isOpen={isTradeUnionDeclineOpen}
								handleCloseDecline={handleCloseDecline}
							></TradeUnionDecline>
							<TradeUnionSignModal
								onClose={setIsTradeUnionOpenSign.bind(null, false)}
								isOpen={isTradeUnionOpenSign}
								externalId={contract.externalId}
								isEmployee={isEmployee}
							></TradeUnionSignModal>
						</>
					) : (
						<>
							<EmployerDecline
								onClose={handleDecline}
								externalId={contract.externalId}
								isOpen={isEmployerDeclineOpen}
								handleCloseDecline={handleCloseDecline}
							></EmployerDecline>
							<EmployerSignModal
								onClose={setIsEmployerOpenSign.bind(null, false)}
								isOpen={isEmployerOpenSign}
								externalId={contract.externalId}
							></EmployerSignModal>
						</>
					)}
				</>
			) : null}
		</ModalTemplate>
	)
}
