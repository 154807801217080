import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { Form, Formik, Field } from 'formik'
import { useTranslation } from 'react-i18next'
import { DatePicker } from '@/components/DatePicker'
import { SelectSearch, Button, Checkbox } from '@/ui'
import { Add } from '@app/icons'
import { contractTerminationSchema } from './contractTermination.schema'
import styles from './ContractTerminationForm.module.scss'
import { AdditonalReasonSelect } from '@/components/AdditionalReasonSelect'
import { HarmfulCheckboxBlock } from '@features/contracts/harmfulCheckboxBlock'
import { calculateAge, updateAnnuityStatus } from '@/utils'
import { useSelector } from 'react-redux'
import { oppvSelector } from '@/entity/oppv'

export const ContractTerminationForm = ({ contract, terminationReasons = [], onSubmit, onCancel, isEdit }) => {
	const {
		termReasonsList,
		terminationDate,
		termReasonDigitalization,
		additionalContracts,
		dworkingConditionsCode,
		employeeRequest,
		employeeConsent,
		employerApprove,
		employeeAccountNumber,
		birthDate,
		hrManagerPhone,
		employeePhone,
	} = contract
	const [additionalReasons, setAdditionalReasons] = useState(isEdit ? termReasonsList.slice(1) : [])
	const { t } = useTranslation()
	const lastTerminationReasons = useMemo(() => terminationReasons.filter(({ last }) => !!last), [terminationReasons])
	const annuityDate = calculateAge(birthDate)
	const [annuity, setAnnuity] = useState(false)
	const [tempTerminationDate, setTempTerminateDate] = useState(null)
	const [checkTerminate, setCheckTerminate] = useState(false)
	const isError = useSelector(oppvSelector.isError)

	useEffect(() => {
		if (tempTerminationDate) {
			let dateParts = tempTerminationDate.split('.')
			let terminateYear = dateParts[2]
			/**
			 * Represents the beginning year of an annuity.
			 *
			 * @type {string}
			 */
			const annuityBeginYear = '2024'
			if (terminateYear >= annuityBeginYear) {
				setCheckTerminate(true)
			} else {
				setCheckTerminate(false)
			}
		}
	}, [tempTerminationDate])

	useEffect(() => {
		if (checkTerminate) {
			updateAnnuityStatus(additionalContracts, dworkingConditionsCode, annuityDate, setAnnuity)
		} else {
			setAnnuity(false)
		}
	}, [checkTerminate])

	const handleAddReasonClick = useCallback(() => {
		setAdditionalReasons((value) => [...value, null])
	}, [])

	const handleRemoveReasonClick = useCallback((index) => {
		setAdditionalReasons((value) => {
			const newArr = [...value]
			newArr.splice(index, 1)
			return newArr
		})
	}, [])

	const handleDataProcess = useCallback(
		(data) => {
			const toSubmit = {
				terminationDate: data.terminationDate,
				termReasonDigitalization: data.termReasonDigitalization,
				terminationReasons: [data.terminationReason, ...additionalReasons],
				employeeRequest: checkTerminate ? data.employeeRequest : null,
				employeeConsent: checkTerminate ? data.employeeConsent : null,
				employerApprove: checkTerminate ? data.employerApprove : null,
				employeeAccountNumber: checkTerminate ? data.employeeAccountNumber : null,
				hrManagerPhone: checkTerminate ? data.hrManagerPhone : null,
				employeePhone: checkTerminate ? data.employeePhone : null,
			}
			onSubmit(toSubmit)
		},
		[additionalReasons, onSubmit, checkTerminate]
	)

	const [disableContinue, setDisableContinue] = useState(false)
	const [disableHarm, setDisableHarm] = useState(false)
	const getFormatDate = (value) => {
		const dateArr = value?.split('.')
		if (dateArr) {
			return `${dateArr?.[1]}.${dateArr?.[0]}.${dateArr?.[2]}`
		} else {
			return false
		}
	}

	const dateArr = contract?.dateFrom?.split('.')

	const validateDate = (value) => {
		if (getFormatDate(value) && getFormatDate(contract?.dateFrom)) {
			const now = new Date()
			const dateFrom = new Date(getFormatDate(contract?.dateFrom))
			const valueDate = new Date(getFormatDate(value))
			setDisableContinue(valueDate > now || valueDate < dateFrom)
			setTempTerminateDate(value)
			setDisableHarm(annuity && isEdit && !checkTerminate)
		}
	}
	return (
		<>
			<Formik
				initialValues={{
					terminationDate: isEdit ? terminationDate : null,
					terminationReason: isEdit ? termReasonsList[0] : null,
					termReasonDigitalization: isEdit ? termReasonDigitalization : false,
					employeeRequest: isEdit ? employeeRequest : false,
					employeeConsent: isEdit ? employeeConsent : false,
					employerApprove: isEdit ? employerApprove : false,
					employeeAccountNumber: isEdit ? employeeAccountNumber : '',
					hrManagerPhone: isEdit ? hrManagerPhone : '',
					employeePhone: isEdit ? employeePhone : '',
				}}
				onSubmit={handleDataProcess}
				validationSchema={contractTerminationSchema}
			>
				{({ isValid, values, setFieldValue }) => {
					validateDate(values.terminationDate)
					return (
						<Form>
							<div className={styles.col}>
								<div className={styles.row}>
									<div className="flex-1">
										<label className={styles.label}>{t('terminate_date')}*</label>
										<Field name="terminationDate">
											{({ field }) => (
												<DatePicker
													minDate={dateArr ? new Date(`${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`) : ''}
													{...field}
													showIcon
													maxDate={new Date()}
												/>
											)}
										</Field>
									</div>
									<div className="flex-2">
										<label className={styles.label}>{t('contract_termination_reason')}</label>
										<div className={styles.selectCol}>
											<div className="flex">
												<Field name="terminationReason">
													{({ field }) => (
														<SelectSearch {...field} className="flex-1" options={lastTerminationReasons} />
													)}
												</Field>
												<Button transparent onClick={handleAddReasonClick} buttonClassName={styles.button}>
													<Add className={styles.iconGreen} />
													{t('add')}
												</Button>
											</div>
											{additionalReasons.map((item, index) => (
												<div key={`additonal-resaons-${index}`} className="flex">
													<AdditonalReasonSelect
														index={index}
														setAdditionalReasons={setAdditionalReasons}
														value={item}
														terminationReasons={terminationReasons}
														removeReason={handleRemoveReasonClick}
													/>
												</div>
											))}
										</div>
									</div>
								</div>
								<div className="flex-1">
									<Field name="termReasonDigitalization">
										{({ field }) => (
											<Checkbox
												{...field}
												label={t('contract_termination_checkbox')}
												disabled={![...additionalReasons, values.terminationReason].includes('03-002')}
												onChange={() => setFieldValue(field.name, !field.value)}
												value={field.value}
											/>
										)}
									</Field>
								</div>
							</div>
							{annuity ? <HarmfulCheckboxBlock isEdit={isEdit} annuity={annuity} /> : null}
							<div className={styles.buttonBlock}>
								<Button transparent onClick={onCancel}>
									{t('cancel')}
								</Button>
								<Button disabled={!isValid || disableContinue || disableHarm || isError !== null} type="submit">
									{t('sign_ecp')}
								</Button>
							</div>
							<div className={styles.errorButton}>
								{annuity && isEdit && !checkTerminate ? (
									<div className={styles.error}>{t('error_message_button')}</div>
								) : null}
							</div>
						</Form>
					)
				}}
			</Formik>
		</>
	)
}
