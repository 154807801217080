import { useTranslation } from 'react-i18next'
import { Button, Title } from '@/ui'
import React, { useCallback, useMemo } from 'react'
import styles from './Composition.module.scss'
import { Edit } from '@app/icons'
import { getCurrentLanguage } from '@/i18next'

const headCells = [
	{
		id: 'iin',
		numeric: true,
		label: 'iin',
	},
	{
		id: 'fio',
		numeric: false,
		label: 'fio',
	},
	{
		id: 'position',
		numeric: true,
		label: 'contract.info.position',
	},
]

export const Composition = ({ tradeUnion, onToggleAction, myUnion }) => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	const { managers } = tradeUnion
	// Создаем новый массив из managers, исключая тех, у кого iin = ""
	const filteredManagers = managers ? managers.filter((manager) => manager.iin !== '') : []
	filteredManagers.unshift({
		iin: tradeUnion.chiefIin,
		fio: tradeUnion.chiefFio,
		position: t('profUnions.chairman_trade_union'),
	})
	const onEdit = () => {
		onToggleAction('edit', true)
		onToggleAction('view', false)
	}

	const getTableRow = useCallback(
		(row, index) => (
			<tr key={index}>
				<td>{row.iin || '-'}</td>
				<td>{row.fio}</td>
				<td>{row.position}</td>
			</tr>
		),
		[lang]
	)

	const tableBody = useMemo(
		() => filteredManagers?.map((row, index) => getTableRow(row, index)),
		[filteredManagers, getTableRow]
	)

	const tableHeader = useMemo(
		() => (
			<>
				{headCells.map((head, index) => (
					<th key={index + 'thead-th'}>{t(head.label)}</th>
				))}
			</>
		),
		[t]
	)

	return (
		<section>
			<div className={styles.docs}>
				<Title>{t('profUnions.titleGoverning')}</Title>
				{myUnion ? null : (
					<Button borderColor="green" transparent buttonClassName={styles.button} onClick={onEdit}>
						<Edit className={styles.iconGreen} />
						{t('edit_button')}
					</Button>
				)}
			</div>
			<table className={styles.table}>
				<thead>
					<tr>{tableHeader}</tr>
				</thead>
				<tbody>{!!filteredManagers?.length > 0 ? tableBody : null}</tbody>
			</table>
		</section>
	)
}
