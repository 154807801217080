import React from 'react'
import { Button, ModalTemplate, Title } from '@/ui'
import styles from './style.module.scss'
import { LoadingBlocker } from '@/components/Loader'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { contractActionsSelectors } from '@features/contracts/model'
import { ViewConfirmDocument } from '@/components/ViewDocuments/ViewConfirmDocument'
import { getCurrentLanguage } from '@/i18next'

export const View = ({ contract, onClose }) => {
	const {
		t,
		i18n: { language, changeLanguage },
	} = useTranslation()
	const lang = getCurrentLanguage()
	const {
		workTimeCollectiveContract,
		relaxTimeCollectiveContract,
		unionTypeCollectiveContract,
		extraGuaranteesCollectiveContract,
	} = useSelector(contractActionsSelectors.options)
	const badge = (items = [], ids = []) =>
		items
			.filter((item) => ids.includes(item.code.toString()))
			.map((item, index) => (
				<div key={index} className={styles.badge}>
					<div>{item[language]}</div>
				</div>
			))
	const showUnion = (code) => {
		const union = unionTypeCollectiveContract.find((item) => item.code === code)
		return union ? union[lang] : null
	}
	const guaranteeName = (code) => {
		const guarantee = extraGuaranteesCollectiveContract.find((item) => item.code === code)
		return guarantee ? guarantee[lang] : null
	}
	return (
		<div>
			<ModalTemplate onClose={onClose}>
				<div className={`scroll ${styles.wrapper}`}>
					<div>
						<div className={styles.mainInformation}>
							<Title color="black">
								{t('col_contract.contract_short_information', {
									contractNumber: contract?.collectiveContractNumber || ' ',
									dateFrom: contract?.dateFrom || ' ',
								})}
							</Title>
						</div>
						<p className={styles.identifier}>ID: {contract.externalId}</p>
						<p className={styles.registrationDate}>
							{t('registration_date')}: {contract.createDate}
						</p>
					</div>
					<div className={styles.informationSections}>
						<section>
							<Title>{t('parties_requisites')}</Title>
							<div className={styles.container}>
								<div className="flex-1">
									<p className={styles.subTitle}>{t('employer')}:</p>
									<div className={styles.tableContainer}>
										<div className={styles.row}>
											<div className={styles.boldCell}>{t('bin')}:</div>
											<div className={styles.cell6}>{contract.bin}</div>
										</div>
										<div className={styles.row}>
											<div className={styles.boldCell}>{t('name')}:</div>
											<div className={styles.cell6}>{contract.companyName}</div>
										</div>
									</div>
								</div>
							</div>
						</section>
						{contract.unions.map((union, unionIndex) => (
							<div key={unionIndex}>
								{(union.unionBin || union.unionName) && (
									<section>
										<div className={styles.container}>
											<div className="flex-1">
												{unionIndex === 0 && <p className={styles.subTitle}>{t('col_contract.trade_union_data')}</p>}
												<p className={styles.subTitle}>
													{t('col_contract.type_union_data')}: {showUnion(union.unionTypeCode)}
												</p>
												<div className={styles.tableContainer}>
													{union.unionBin && (
														<div className={styles.row}>
															<div className={styles.boldCell}>{t('bin')}:</div>
															<div className={styles.cell6}>{union.unionBin}</div>
														</div>
													)}
													{union.unionName && (
														<div className={styles.row}>
															<div className={styles.boldCell}>{t('name')}:</div>
															<div className={styles.cell6}>
																{lang === 'rus' ? union.unionName : union.unionNameKaz}
															</div>
														</div>
													)}
												</div>
											</div>
										</div>
									</section>
								)}
								{union.representativeDtos.map((representative, repIndex) => (
									<section key={repIndex}>
										<div className={styles.container}>
											<div className="flex-1">
												{repIndex === 0 && (
													<>
														{!union.unionBin && !union.unionName && (
															<p className={styles.subTitle}>
																{t('col_contract.type_union_data')}: {showUnion(union.unionTypeCode)}
															</p>
														)}
														<p className={styles.subTitle}>{t('col_contract.representativeTradeUnion')}</p>
													</>
												)}
												<div className={styles.tableContainer}>
													<div className={styles.row}>
														<div className={styles.boldCell}>{t('iin')}:</div>
														<div className={styles.cell6}>{representative?.representativeIin || '-'}</div>
													</div>
													<div className={styles.row}>
														<div className={styles.boldCell}>{t('fio')}:</div>
														<div className={styles.cell6}>{representative.representativeFIO}</div>
													</div>
												</div>
											</div>
										</div>
									</section>
								))}
							</div>
						))}
						{contract.files && (
							<section>
								<Title>{t('col_contract.scan')}</Title>
								<ViewConfirmDocument data={contract.files} local={true}></ViewConfirmDocument>
							</section>
						)}
						<section>
							<Title>{t('col_contract.info')}</Title>
							<div className={styles.tableContainer}>
								<div className={styles.row}>
									<div className={styles.boldCell}>{t('col_contract.number')}:</div>
									<div className={styles.cell}>{contract.collectiveContractNumber}</div>
								</div>
								<div className={styles.row}>
									<div className={styles.boldCell}>{t('col_contract.dateFrom')}:</div>
									<div className={styles.cell}>{contract.dateFrom}</div>
								</div>
								<div className={styles.row}>
									<div className={styles.boldCell}>{t('col_contract.dateTo')}:</div>
									<div className={styles.cell}>{contract.dateTo}</div>
								</div>
							</div>
						</section>
						{contract.collectiveContractDetails && (
							<>
								<section>
									<Title>{t('col_contract.info')}</Title>
									<div className={styles.table}>
										{contract.collectiveContractDetails.overtimePay && (
											<div className={styles.row}>
												<div className={styles.boldCell}>{t('col_contract.pay')}:</div>
												<div className={styles.cell}>{contract.collectiveContractDetails.overtimePay}</div>
											</div>
										)}
										{contract.collectiveContractDetails.holidayPay && (
											<div className={styles.row}>
												<div className={styles.boldCell}>{t('col_contract.holiday_pay')}:</div>
												<div className={styles.cell}>{contract.collectiveContractDetails.holidayPay}</div>
											</div>
										)}
										{contract.collectiveContractDetails.nightTimePay && (
											<div className={styles.row}>
												<div className={styles.boldCell}>{t('col_contract.night_time_pay')}:</div>
												<div className={styles.cell}>{contract.collectiveContractDetails.nightTimePay}</div>
											</div>
										)}
										{contract.collectiveContractDetails.combinationPositionPay && (
											<div className={styles.row}>
												<div className={styles.boldCell}>{t('col_contract.combination_position_pay')}:</div>
												<div className={styles.cell}>{contract.collectiveContractDetails.combinationPositionPay}</div>
											</div>
										)}
										{contract.collectiveContractDetails.termCompensationPay && (
											<div className={styles.row}>
												<div className={styles.boldCell}>{t('col_contract.term_compensation_pay')}:</div>
												<div className={styles.cell}>{contract.collectiveContractDetails.termCompensationPay}</div>
											</div>
										)}
										{contract.collectiveContractDetails.wayCompensationPay && (
											<div className={styles.row}>
												<div className={styles.boldCell}>{t('col_contract.way_compensation_pay')}:</div>
												<div className={styles.cell}>{contract.collectiveContractDetails.wayCompensationPay}</div>
											</div>
										)}
										{contract.collectiveContractDetails.wageIndexProcedure && (
											<div className={styles.row}>
												<div className={styles.boldCell}>{t('col_contract.wage_index_procedure')}:</div>
												<div className={styles.cell}>{contract.collectiveContractDetails.wageIndexProcedure}</div>
											</div>
										)}
									</div>
								</section>
								<section>
									<Title>{t('col_contract.information_tariff_rates')}</Title>
									<div className={styles.tableContainer}>
										<div className={styles.row}>
											<div className={styles.boldCell}>{t('col_contract.tariff_rate_category_workers')}:</div>
											<div className={styles.cell}>{contract.collectiveContractDetails.rateInfo.firstCategoryRate}</div>
										</div>
										<div className={styles.row}>
											<div className={styles.boldCell}>{t('col_contract.industry_agreement_coefficient')}:</div>
											<div className={styles.cell}>
												{contract.collectiveContractDetails.rateInfo.industryAgreementCoefficient}
											</div>
										</div>
										<div className={styles.row}>
											<div className={styles.boldCell}>{t('col_contract.min_coefficient')}:</div>
											<div className={styles.cell}>{contract.collectiveContractDetails.rateInfo.minCoefficient}</div>
										</div>
										<div className={styles.row}>
											<div className={styles.boldCell}>{t('col_contract.max_coefficient')}:</div>
											<div className={styles.cell}>{contract.collectiveContractDetails.rateInfo.maxCoefficient}</div>
										</div>
									</div>
								</section>
								<section>
									<Title>{t('col_contract.different_coefficient')}</Title>
									<div className={styles.tableContainer}>
										<div className={styles.row}>
											<div className={styles.boldCell}>{t('col_contract.forWorkers', { number: 1 }) + ':'}</div>
											<div className={styles.cell}>
												{t('col_contract.fromTo', {
													min: contract.collectiveContractDetails.firstGradeMin,
													max: contract.collectiveContractDetails.firstGradeMax,
												})}
											</div>
										</div>
										<div className={styles.row}>
											<div className={styles.boldCell}>{t('col_contract.forWorkers', { number: 2 }) + ':'}</div>
											<div className={styles.cell}>
												{t('col_contract.fromTo', {
													min: contract.collectiveContractDetails.secondGradeMin,
													max: contract.collectiveContractDetails.secondGradeMax,
												})}
											</div>
										</div>
										<div className={styles.row}>
											<div className={styles.boldCell}>{t('col_contract.forWorkers', { number: 3 }) + ':'}</div>
											<div className={styles.cell}>
												{t('col_contract.fromTo', {
													min: contract.collectiveContractDetails.thirdGradeMin,
													max: contract.collectiveContractDetails.thirdGradeMax,
												})}
											</div>
										</div>
										<div className={styles.row}>
											<div className={styles.boldCell}>{t('col_contract.forWorkers', { number: 4 }) + ':'}</div>
											<div className={styles.cell}>
												{t('col_contract.fromTo', {
													min: contract.collectiveContractDetails.fourthGradeMin,
													max: contract.collectiveContractDetails.fourthGradeMax,
												})}
											</div>
										</div>
										<div className={styles.row}>
											<div className={styles.boldCell}>{t('col_contract.forWorkers', { number: 5 }) + ':'}</div>
											<div className={styles.cell}>
												{t('col_contract.fromTo', {
													min: contract.collectiveContractDetails.fifthGradeMin,
													max: contract.collectiveContractDetails.fifthGradeMax,
												})}
											</div>
										</div>
										<div className={styles.row}>
											<div className={styles.boldCell}>{t('col_contract.forWorkers', { number: 6 }) + ':'}</div>
											<div className={styles.cell}>
												{t('col_contract.fromTo', {
													min: contract.collectiveContractDetails.sixthGradeMin,
													max: contract.collectiveContractDetails.sixthGradeMax,
												})}
											</div>
										</div>
									</div>
								</section>
								<section>
									<Title>{t('col_contract.safe_working_protection')}</Title>
									<div className={styles.table}>
										<div className={styles.row}>
											<div className={styles.boldCell}>{t('col_contract.amount_funding')}:</div>
											<div className={styles.cell}>{contract.collectiveContractDetails.securityFundingVolume}</div>
										</div>
										<div className={styles.row}>
											<div className={styles.boldCell}>{t('col_contract.funding_amount')}:</div>
											<div className={styles.cell}>{contract.collectiveContractDetails.fundingAmount}</div>
										</div>
									</div>
								</section>
								<section>
									<Title>{t('col_contract.duration_working_hours_and_rest_time_holidays')}</Title>
									<div className={styles.tableContainer}>
										<div className={styles.row}>
											<div className={styles.boldCell}>{t('col_contract.working_time')}:</div>
										</div>
										<div>{badge(workTimeCollectiveContract, contract.collectiveContractDetails.workTime)}</div>
									</div>
									<div className={styles.tableContainer}>
										<div className={styles.row}>
											<div className={styles.boldCell}>{t('col_contract.relax_time')}:</div>
										</div>
										<div>{badge(relaxTimeCollectiveContract, contract.collectiveContractDetails.relaxTime)}</div>
									</div>
								</section>

								<section>
									<Title>{t('col_contract.additional_information')}</Title>
									<div className={styles.tableContainer}>
										{contract.extraGuarantee.map((guarantee) => (
											<div key={guarantee.code} className={styles.row}>
												<div className={styles.boldCell}>{guaranteeName(guarantee.code)}:</div>
												<div className={styles.cell}>{guarantee.value}</div>
											</div>
										))}
									</div>
								</section>
							</>
						)}
					</div>
					{contract ? (
						<div className={styles.buttonEnd}>
							<Button onClick={onClose}>{t('close')}</Button>
						</div>
					) : (
						<LoadingBlocker localStyles={{ width: '65vw', height: '50vw' }} />
					)}
				</div>
			</ModalTemplate>
		</div>
	)
}
