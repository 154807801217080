import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './UnionsForm.module.scss'
import { Field as FormikField, Form as FormikForm, useFormikContext } from 'formik'
import { Button, Field, Title } from '@/ui'
import { profUnionsSelectors, profUnionsThunks, userSelectors } from '@/entity'
import { useDispatch, useSelector } from 'react-redux'
import { formFieldNames } from './formFieldNames'
import { Add, Trash } from '@app/icons'
import { ConfirmDocument } from '@/components/AddDocuments/ConfirmDocument'
import { api } from '@/shared'
import { Loader } from '@/components/Loader'
import { message } from 'antd'
import {
	getEmployerProfParent,
	getEmployerProfUnitsEmployerBin,
	getEmployerProfUnitsUnionName,
} from '@/entity/profUnions/model/thunks'
import { isLoadingChiefFio } from '@/entity/profUnions/model/selectors'

export const UnionsForm = ({
	options = {},
	attachFileCharter,
	attachFileProtocol,
	attachFileReference,
	fileCharter,
	fileProtocol,
	fileReference,
	onCancel,
	actions,
	cabinetTradeUnion = true,
}) => {
	const { t } = useTranslation()
	const { values, setFieldValue } = useFormikContext()
	const dispatch = useDispatch()
	const searchChiefFio = useSelector(profUnionsSelectors.searchChiefFio)
	const searchName = useSelector(profUnionsSelectors.searchName)
	const searchParentName = useSelector(profUnionsSelectors.searchParentName)
	const searchEmployerName = useSelector(profUnionsSelectors.searchEmployerName)
	const isLoadingChiefFio = useSelector(profUnionsSelectors.isLoadingChiefFio)
	const isLoadingBin = useSelector(profUnionsSelectors.isLoadingBin)
	const isLoadingBinParent = useSelector(profUnionsSelectors.isLoadingBinParent)
	const isLoadingBinEmployer = useSelector(profUnionsSelectors.isLoadingBinEmployer)
	const typeCode = useSelector(profUnionsSelectors.typeCode)
	const isEmployee = useSelector(userSelectors.activeRole) === 'ROLE_EMPLOYEE'
	const [documentList, setDocList] = useState([])
	const [disableErrorSize, setDisableErrorSize] = useState(true)
	const [checkDoc, setCheckDoc] = useState(true)
	const [isManagersVisible, setIsManagersVisible] = useState(false)
	const [isIndustryVisible, setIndustryVisible] = useState(false)
	const [isLocalVisible, setLocalVisible] = useState(false)
	const [isPrimaryVisible, setPrimaryVisible] = useState(false)
	const { unionType, okedsTop } = options
	const [filteredUnionType, setFilteredUnionType] = useState(unionType)

	useEffect(() => {
		if (actions.create) {
			let newFilteredUnionType = unionType
			if (isEmployee) {
				newFilteredUnionType = newFilteredUnionType.filter((option) => option.code === 'primary')
			} else if (cabinetTradeUnion) {
				newFilteredUnionType = newFilteredUnionType.filter((option) => option.code !== 'primary')
			}
			if (typeCode === 'industry' || !cabinetTradeUnion) {
				newFilteredUnionType = newFilteredUnionType.filter((option) => option.code !== 'industry')
			} else if (typeCode === 'local') {
				newFilteredUnionType = newFilteredUnionType.filter((option) => option.code === 'primary')
			}
			setFilteredUnionType(newFilteredUnionType)
		}
	}, [typeCode, isEmployee, unionType])

	const onGetHandBookOptions = useCallback(
		(value) => {
			dispatch(profUnionsThunks.getPerson({ iin: value }))
		},
		[dispatch]
	)
	const onGetBin = useCallback(
		(value, typeBin = '') => {
			if (typeBin === 'unionName') {
				dispatch(profUnionsThunks.getEmployerProfUnitsUnionName({ bin: value }))
			} else if (typeBin === 'employerBin') {
				dispatch(profUnionsThunks.getEmployerProfUnitsEmployerBin({ bin: value }))
			} else {
				dispatch(profUnionsThunks.getEmployerProfParent({ bin: value }))
			}
		},
		[dispatch]
	)

	const handleSearchBin = async (searchField, typeBin) => {
		if (values.typeCode.length < 1) {
			return message.error('Выберите: "Тип проф.союза"')
		}
		if (values[searchField].length < 12) {
			return
		}
		const regexp = /^[0-9]{12}$/
		if (regexp.test(values[searchField])) {
			onGetBin(values[searchField], typeBin)
		}
	}

	const handleSearchIin = async (searchField) => {
		if (values[searchField].length < 12) {
			return
		}
		const regexp = /^[0-9]{12}$/
		if (regexp.test(values[searchField])) {
			onGetHandBookOptions(values[searchField])
		}
	}

	const handleSearchMember = async (iin, managerIndex) => {
		if (iin.length < 12) {
			return
		}
		const { fullNamePerson } = await api.profile.getEmployerActivityByIIN({ iin: iin })
		setFieldValue(`managers[${managerIndex}].fio`, fullNamePerson)
	}

	const addStructManagers = () => {
		setFieldValue('managers', [
			...values.managers,
			{
				iin: '',
				fio: '',
				position: '',
			},
		])
		setIsManagersVisible(true)
	}

	const removeManagers = (index) => {
		const updatedUnits = [...values.managers]
		updatedUnits.splice(index, 1)
		setFieldValue('managers', updatedUnits)
	}

	const getDocumentCharter = (doc) => {
		setDocList(doc)
		attachFileCharter(doc)
	}
	const getDocumentProtocol = (doc) => {
		setDocList(doc)
		attachFileProtocol(doc)
	}
	const getDocumentReference = (doc) => {
		setDocList(doc)
		attachFileReference(doc)
	}
	const getErrorSize = (getErrorSize) => {
		setDisableErrorSize(getErrorSize)
	}
	useEffect(() => {
		setCheckDoc(documentList.length >= 1 && !disableErrorSize)
	}, [documentList, disableErrorSize])

	useEffect(() => {
		if (searchChiefFio) {
			setFieldValue('chiefFio', searchChiefFio)
		}
	}, [searchChiefFio, cabinetTradeUnion])

	useEffect(() => {
		if (searchName) {
			setFieldValue('unionName', searchName)
		}
	}, [searchName])

	useEffect(() => {
		if (searchParentName) {
			setFieldValue('parentName', searchParentName)
		}
	}, [searchParentName, cabinetTradeUnion])

	useEffect(() => {
		if (searchEmployerName && !isIndustryVisible) {
			setFieldValue('employerName', searchEmployerName)
		}
	}, [searchEmployerName, isLocalVisible])

	useEffect(() => {
		if (!values.typeCode) return
		// Default visibility
		let localVisible = false,
			industryVisible = false,
			primaryVisible = false
		if (values.typeCode === 'local') {
			localVisible = true
		} else if (values.typeCode === 'industry') {
			industryVisible = true
		} else if (values.typeCode === 'primary') {
			primaryVisible = true
		}
		// Set the visibility
		setLocalVisible(localVisible)
		setIndustryVisible(industryVisible)
		setPrimaryVisible(primaryVisible)
	}, [values.typeCode])

	return (
		<FormikForm className={styles.form}>
			<section>
				<Title>{t('profUnions.unionInfo')}</Title>
				<div className={`${styles.fields} ${styles.twoFields} mt-4`}>
					<FormikField name={formFieldNames.dateCreate}>
						{({ field, meta: { touched, error } }) => (
							<Field
								fieldType="datePicker"
								label={t('profUnions.createDate')}
								isRequired
								placeholder={t('default_date_value')}
								minDate={new Date(1900, 0, 1)}
								error={touched && error}
								{...field}
							/>
						)}
					</FormikField>
					<FormikField name={formFieldNames.typeCode}>
						{({ field, meta: { touched, error } }) => (
							<Field
								fieldType={'select'}
								isRequired
								label={t('profUnions.unionType')}
								placeholder={t('select_from_list')}
								options={filteredUnionType}
								error={touched && error}
								{...field}
								disabled={actions.edit}
							/>
						)}
					</FormikField>
				</div>
				{isPrimaryVisible ? (
					<div className={styles.mbFields}>
						<FormikField name={formFieldNames.unionName}>
							{({ field, meta: { touched, error } }) => (
								<Field
									type="text"
									wrapperClassName={styles.field__input}
									label={t('name')}
									placeholder={t('profUnions.placeholder_primary')}
									{...field}
									error={touched && error ? t(error) : ''}
									isRequired
								/>
							)}
						</FormikField>
					</div>
				) : (
					<div className={`${styles.fields__wrapper} ${styles.mbFields}`}>
						<div className={styles.fieldAndButton}>
							<FormikField name={formFieldNames.bin}>
								{({ field, meta: { touched, error } }) => (
									<Field
										type="text"
										label={t('bin')}
										isRequired
										error={touched && error}
										disabled={cabinetTradeUnion}
										placeholder={t('enter_count_digitals', { count: 12 })}
										maxLength={12}
										pattern="[0-9]{12}"
										onInput={(e) => {
											e.target.value = e.target.value.replace(/[^0-9]/g, '')
										}}
										{...field}
									/>
								)}
							</FormikField>
							{isLoadingBin ? (
								<div className={styles.loadingBin}>
									<Loader></Loader>
								</div>
							) : (
								<Button
									disabled={!values[formFieldNames.bin]}
									onClick={() => handleSearchBin(formFieldNames.bin, 'unionName')}
								>
									{t('find')}
								</Button>
							)}
						</div>
						<div className={styles.fields__item__no__button}>
							<FormikField name={formFieldNames.unionName}>
								{({ field, meta: { touched, error } }) => (
									<Field
										type="text"
										disabled
										wrapperClassName={styles.field__input}
										label={t('name')}
										placeholder={t('filled_automate')}
										{...field}
										error={touched && error ? t(error) : ''}
										isRequired
									/>
								)}
							</FormikField>
						</div>
					</div>
				)}
				{isIndustryVisible ? (
					<div className={styles.mbFields}>
						<FormikField name={formFieldNames.industryCode}>
							{({ field, meta: { touched, error } }) => (
								<Field
									fieldType={'select'}
									isRequired
									label={t('profUnions.industry')}
									placeholder={t('select_from_list')}
									options={okedsTop}
									error={touched && error}
									{...field}
								/>
							)}
						</FormikField>
					</div>
				) : null}
				<div className={styles.mbFields}>
					<FormikField name={formFieldNames.unionNote}>
						{({ field, meta: { touched, error } }) => (
							<Field
								fieldType={'textarea'}
								label={t('profUnions.descriptionUnion')}
								placeholder={t('profUnions.placeholderUnion')}
								error={touched && error ? t(error) : ''}
								{...field}
								showCount
								maxLength={4000}
								rows={6}
								{...field}
							/>
						)}
					</FormikField>
				</div>
				{isLocalVisible || isPrimaryVisible ? (
					<div className={styles.mbFields}>
						<p>{t('profUnions.structuralUnit')}</p>
						<div className={styles.fields__wrapper}>
							<div className={styles.fieldAndButton}>
								<FormikField name={formFieldNames.parentBin}>
									{({ field, meta: { touched, error } }) => (
										<Field
											wrapperClassName={styles.field__input}
											hideNumberArrows
											label={t('bin')}
											placeholder={t('enter_count_digitals', { count: 12 })}
											{...field}
											error={touched && error ? t(error) : ''}
											isRequired
											maxLength={12}
											pattern="[0-9]{12}"
											onInput={(e) => {
												e.target.value = e.target.value.replace(/[^0-9]/g, '')
											}}
										/>
									)}
								</FormikField>
								{isLoadingBinParent ? (
									<div className={styles.loadingBin}>
										<Loader></Loader>
									</div>
								) : (
									<>
										<Button
											disabled={!values[formFieldNames.parentBin]}
											onClick={() => handleSearchBin(formFieldNames.parentBin)}
										>
											{t('find')}
										</Button>
									</>
								)}
							</div>
							<div className={styles.fields__item__no__button}>
								<FormikField name={formFieldNames.parentName}>
									{({ field, meta: { touched, error } }) => (
										<Field
											type="text"
											disabled
											wrapperClassName={styles.field__input}
											label={t('name')}
											placeholder={t('filled_automate')}
											{...field}
											error={touched && error ? t(error) : ''}
											isRequired
										/>
									)}
								</FormikField>
							</div>
						</div>
					</div>
				) : null}
			</section>
			{!isIndustryVisible ? (
				<section>
					<Title>{t('employer_data')}</Title>
					<div className={styles.fields__wrapper}>
						<div className={styles.fieldAndButton}>
							<FormikField name={formFieldNames.employerBin}>
								{({ field, meta: { touched, error } }) => (
									<Field
										wrapperClassName={styles.field__input}
										hideNumberArrows
										label={t('col_contract.bin')}
										placeholder={t('enter_count_digitals', { count: 12 })}
										{...field}
										error={touched && error ? t(error) : ''}
										isRequired
										maxLength={12}
										pattern="[0-9]{12}"
										onInput={(e) => {
											e.target.value = e.target.value.replace(/[^0-9]/g, '')
										}}
									/>
								)}
							</FormikField>
							{isLoadingBinEmployer ? (
								<div className={styles.loadingBin}>
									<Loader></Loader>
								</div>
							) : (
								<>
									<Button
										disabled={!values[formFieldNames.employerBin]}
										onClick={() => handleSearchBin(formFieldNames.employerBin, 'employerBin')}
									>
										{t('find')}
									</Button>
								</>
							)}
						</div>
						<div className={styles.fields__item__no__button}>
							<FormikField name={formFieldNames.employerName}>
								{({ field, meta: { touched, error } }) => (
									<Field
										type="text"
										disabled
										wrapperClassName={styles.field__input}
										label={t('col_contract.name')}
										placeholder={t('filled_automate')}
										{...field}
										error={touched && error ? t(error) : ''}
										isRequired
									/>
								)}
							</FormikField>
						</div>
					</div>
				</section>
			) : null}
			<section>
				<Title>{t('profUnions.titleGoverning')}</Title>
				<div className={styles.fields__wrapper}>
					<div className={styles.fieldAndButton}>
						<FormikField name={formFieldNames.chiefIin}>
							{({ field, meta: { touched, error } }) => (
								<Field
									wrapperClassName={styles.field__input}
									hideNumberArrows
									label={t('profUnions.innChairman')}
									placeholder={t('enter_count_digitals', { count: 12 })}
									{...field}
									error={touched && error ? t(error) : ''}
									isRequired
									maxLength={12}
									pattern="[0-9]{12}"
									onInput={(e) => {
										e.target.value = e.target.value.replace(/[^0-9]/g, '')
									}}
								/>
							)}
						</FormikField>
						{isLoadingChiefFio ? (
							<div className={styles.loadingBin}>
								<Loader></Loader>
							</div>
						) : (
							<Button
								disabled={!values[formFieldNames.chiefIin]}
								onClick={handleSearchIin.bind(null, formFieldNames.chiefIin)}
							>
								{t('find')}
							</Button>
						)}
					</div>
					<div className={styles.fields__item__no__button}>
						<FormikField name={formFieldNames.chiefFio}>
							{({ field, meta: { touched, error } }) => (
								<Field
									type="text"
									disabled
									wrapperClassName={styles.field__input}
									label={t('employee_initials')}
									placeholder={t('filled_automate')}
									{...field}
									error={touched && error ? t(error) : ''}
									isRequired
								/>
							)}
						</FormikField>
					</div>
				</div>
				<div>
					{isManagersVisible &&
						values.managers.map((manager, managerIndex) => (
							<React.Fragment key={managerIndex}>
								<p className={styles.subTitle}>
									{t('profUnions.manager')} {managerIndex + 1}
								</p>
								<div className={styles.fields__wrapper}>
									<div className={styles.fieldAndButton}>
										<FormikField name={`managers.${managerIndex}.iin`}>
											{({ field, meta: { touched, error } }) => (
												<Field
													{...field}
													placeholder={t('enter_count_digitals', { count: 12 })}
													error={touched && error ? t(error) : ''}
													wrapperClassName={styles.field__input}
													label={t('iin')}
													maxLength={12}
													pattern="[0-9]{12}"
													onInput={(e) => {
														e.target.value = e.target.value.replace(/[^0-9]/g, '')
													}}
												/>
											)}
										</FormikField>
										<Button onClick={() => handleSearchMember(manager.iin, managerIndex)}>{t('find')}</Button>
									</div>
									<div className={styles.fields__item__no__button}>
										<FormikField name={`managers.${managerIndex}.fio`}>
											{({ field, meta: { touched, error } }) => (
												<Field
													type="text"
													disabled
													wrapperClassName={styles.field__input}
													label={t('employee_initials')}
													placeholder={t('filled_automate')}
													{...field}
													error={touched && error ? t(error) : ''}
												/>
											)}
										</FormikField>
									</div>
								</div>
								<div className={styles.mbFields}>
									<FormikField name={`managers.${managerIndex}.position`}>
										{({ field, meta: { touched, error } }) => (
											<Field
												type="text"
												wrapperClassName={styles.field__input}
												label={t('profUnions.position')}
												placeholder={t('profUnions.position')}
												error={touched && error}
												{...field}
											/>
										)}
									</FormikField>
								</div>
								{actions.create ? (
									<Button
										transparent
										onClick={() => removeManagers(managerIndex)}
										buttonClassName={styles.buttonRed}
										disabled={false}
									>
										<Trash className={styles.iconRed} />
										{t('remove')}
									</Button>
								) : null}
							</React.Fragment>
						))}
					{actions.create ? (
						<Button transparent onClick={addStructManagers} buttonClassName={styles.button}>
							<Add className={styles.iconGreen} />
							{t('profUnions.addMember')}
						</Button>
					) : null}
				</div>
			</section>
			<div>
				{isLocalVisible || isIndustryVisible || isPrimaryVisible ? (
					<div>
						<section>
							<Title>{isPrimaryVisible ? t('profUnions.tradeUnionRegulations') : t('profUnions.charter')}</Title>
							<ConfirmDocument
								getDocument={getDocumentCharter}
								getErrorSize={getErrorSize}
								fileType={'TRADEUNION'}
								fileList={fileCharter}
								maxFiles={1}
							></ConfirmDocument>
						</section>
						<section>
							<Title>{t('profUnions.protocol')}</Title>
							<ConfirmDocument
								getDocument={getDocumentProtocol}
								getErrorSize={getErrorSize}
								fileType={'TRADEUNION'}
								fileList={fileProtocol}
								maxFiles={1}
							></ConfirmDocument>
						</section>
						{!isPrimaryVisible ? (
							<section>
								<Title>{t('profUnions.registration')}</Title>
								<ConfirmDocument
									getDocument={getDocumentReference}
									getErrorSize={getErrorSize}
									fileType={'TRADEUNION'}
									fileList={fileReference}
									maxFiles={1}
								></ConfirmDocument>
							</section>
						) : null}
					</div>
				) : null}
				<div className={styles.actions}>
					<Button type="bare" onClick={onCancel}>
						{t('cancel')}
					</Button>
					<Button disabled={!checkDoc} type="submit">
						{t('sign_and_save')}
					</Button>
				</div>
			</div>
		</FormikForm>
	)
}
