import { connect, Field as FormikField } from 'formik'
import { useTranslation } from 'react-i18next'
import { formFieldNames } from '@/entity'
import React, { useCallback, useEffect } from 'react'
import { Field } from '@/ui'

export const RegionField = connect(
	({ options = [], formik: { setFieldValue, values }, loadLocalities, isFormDirty}) => {
		const { t } = useTranslation()

		return (
			<FormikField name={formFieldNames.regionWork}>
				{({ field, meta: { touched, error } }) => {
					useEffect(() => loadLocalities(field.value, true), [field.value])

					const onChange = useCallback((code) => {
						setFieldValue(formFieldNames.regionWork, code)
						setFieldValue(formFieldNames.locality, null)
					}, [])

					return (
						<Field
							type="text"
							fieldType="select"
							label={t('contracts_tab.distrcit_work')}
							placeholder={t('choose_from_handbook')}
							options={options}
							error={(touched || isFormDirty) && error}
							disabled={values[formFieldNames.countryWork] !== '398'} // Не активно, если выбрана страна не Казахстан
							isRequired={values[formFieldNames.countryWork] === '398'} // Обязательное, если страна Казахстан
							{...field}
							onChange={onChange}
						/>
					)
				}}
			</FormikField>
		)
	}
)
