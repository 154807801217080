import { handbookThunks } from '@/entity/handbook'
import { eColContractsActions } from '@/entity'
import { api } from '@/shared'
import { message } from 'antd'
import { revoke } from '@/shared/api/rest/colContracts'

export const getOptions = () => {
	return (dispatch, getState) => {
		const {
			workTimeCollectiveContract,
			relaxTimeCollectiveContract,
			unionTypeCollectiveContract,
			extraGuaranteesCollectiveContract,
			ccEnterpriseType,
			ccOwnershipType,
		} = getState().handbook
		if (!workTimeCollectiveContract.length) dispatch(handbookThunks.getWorkTime())
		if (!relaxTimeCollectiveContract.length) dispatch(handbookThunks.getRelaxTime())
		if (!unionTypeCollectiveContract.length) dispatch(handbookThunks.getUnionTypeCollectiveContract())
		if (!extraGuaranteesCollectiveContract.length) dispatch(handbookThunks.getExtraGuaranteesCollectiveContract())
		if (!ccEnterpriseType.length) dispatch(handbookThunks.getCcEnterpriseType())
		if (!ccOwnershipType.length) dispatch(handbookThunks.getCcOwnershipType())
	}
}

export const getColContracts = (page, size, isEmployee) => {
	return async (dispatch) => {
		try {
			dispatch(eColContractsActions.toggleError(null))
			dispatch(eColContractsActions.toggleLoading(true))
			const response = await api.colContracts.getEContracts({ page, size, isEmployee })
			dispatch(eColContractsActions.setEColContracts(response))
			dispatch(eColContractsActions.toggleSuccess(true))
		} catch (error) {
			dispatch(eColContractsActions.toggleError(error))
			dispatch(eColContractsActions.toggleSuccess(false))
			message.error(error?.response?.data?.message)
		} finally {
			dispatch(eColContractsActions.toggleLoading(false))
		}
	}
}

export const getColContract = (externalId) => {
	return async (dispatch) => {
		try {
			dispatch(eColContractsActions.toggleError(null))
			dispatch(eColContractsActions.setEColContractLoading(true))
			const response = await api.colContracts.getEContract(externalId)
			dispatch(eColContractsActions.setEColContract(response))
		} catch (error) {
			dispatch(eColContractsActions.setEColContractError(error))
			message.error(error?.response?.data?.message)
		} finally {
			dispatch(eColContractsActions.setEColContractLoading(false))
		}
	}
}

export const getHtml = (data, isEmployee) => {
	return async (dispatch) => {
		try {
			dispatch(eColContractsActions.setEColContractLoading(true))
			const eHtml = await api.colContracts.getHtml(data, isEmployee)
			dispatch(eColContractsActions.setEHtml(eHtml))
			dispatch(eColContractsActions.setEHtmlSuccess(true))
		} catch (error) {
			dispatch(eColContractsActions.setEColContractError(error))
			message.error(
				'Ошибка получения данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(eColContractsActions.setEColContractLoading(false))
			dispatch(eColContractsActions.setEHtmlSuccess(false))
		}
	}
}

export const getEditHtml = (data, isEmployee) => {
	return async (dispatch) => {
		try {
			dispatch(eColContractsActions.setEColContractLoading(true))
			const eHtml = await api.colContracts.getHtml(data, isEmployee)
			dispatch(eColContractsActions.setCurrentEcdEdit(eHtml))
		} catch (error) {
			dispatch(eColContractsActions.setEColContractError(error))
			message.error(
				'Ошибка получения данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(eColContractsActions.setEColContractLoading(false))
		}
	}
}

export const getEcd = (externalId) => {
	return async (dispatch) => {
		try {
			dispatch(eColContractsActions.setEColContractLoading(true))
			const ecd = await api.colContracts.getEcd(externalId)
			dispatch(eColContractsActions.setCurrentEcd(ecd))
			dispatch(eColContractsActions.setEHtmlSuccess(true))
		} catch (error) {
			message.error(
				'Ошибка получения данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(eColContractsActions.setEColContractLoading(false))
			dispatch(eColContractsActions.setEHtmlSuccess(false))
		}
	}
}

export const save = (data, isSend, isEmployee) => {
	return async (dispatch) => {
		try {
			dispatch(eColContractsActions.setEColContractLoading(true))
			const response = await api.colContracts.save(data, isSend, isEmployee)
			if (response.success) {
				dispatch(eColContractsActions.setEColContractSuccess(true))
			}
			message.success('Данные успешно сохранены', 10)
		} catch (error) {
			dispatch(eColContractsActions.setEColContractError(error))
			message.error(
				'Ошибка получения данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(eColContractsActions.setEColContractLoading(false))
		}
	}
}

export const sendTo = (isEmployee, externalId) => {
	return async (dispatch) => {
		try {
			dispatch(eColContractsActions.setEColContractLoading(true))
			const response = await api.colContracts.sendTo(isEmployee, externalId)
			if (response === '') {
				dispatch(eColContractsActions.setEColContractSendSuccess(true))
			}
			message.success('Данные успешно отправлены', 10)
		} catch (error) {
			dispatch(eColContractsActions.setEColContractError(error))
			message.error(
				'Ошибка отправки данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(eColContractsActions.setEColContractLoading(false))
		}
	}
}

export const generateXML = (data) => {
	return async (dispatch) => {
		try {
			dispatch(eColContractsActions.setEColContractLoading(true))
			const contractXml = await api.colContracts.getEmployerSignedXml(data)
			dispatch(eColContractsActions.setEColXML(contractXml))
		} catch (error) {
			dispatch(eColContractsActions.toggleError(error))
			message.error(
				'Ошибка отправки данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(eColContractsActions.setEColContractLoading(false))
		}
	}
}

export const sendSignedEmployerColContract = (signedXml) => {
	return async (dispatch) => {
		try {
			dispatch(eColContractsActions.setEColContractLoading(true))
			const response = await api.colContracts.sendEmployerSignedXml(signedXml)
			dispatch(eColContractsActions.setEColContract(response))
			dispatch(eColContractsActions.setEColContractSuccess(true))
			message.success('Данные успешно сохранены', 10)
		} catch (error) {
			dispatch(eColContractsActions.setEColContractLoading(false))
			dispatch(eColContractsActions.toggleError(error))
			message.error(
				'Ошибка отправки данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(eColContractsActions.setEColContractLoading(false))
		}
	}
}

export const declineEmployer = (externalId, note) => {
	return async (dispatch) => {
		try {
			dispatch(eColContractsActions.setEColContractLoading(true))
			const response = await api.colContracts.decline(externalId, note)
			if (response === '') {
				dispatch(eColContractsActions.setEColContractSuccess(true))
				message.success('Договор отклонён', 10)
			}
		} catch (error) {
			dispatch(eColContractsActions.setEColContractLoading(false))
			dispatch(eColContractsActions.toggleError(error))
			message.error(
				'Ошибка отправки данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(eColContractsActions.setEColContractLoading(false))
		}
	}
}

export const rejectTradeUnion = (externalId, isEmployee) => {
	return async (dispatch) => {
		try {
			dispatch(eColContractsActions.setEColContractLoading(true))
			const response = await api.colContracts.rejectTradeUnion(externalId, isEmployee)
			if (response === '') {
				dispatch(eColContractsActions.setEColContractSuccess(true))
				message.success('Договор отклонён', 10)
			}
		} catch (error) {
			dispatch(eColContractsActions.setEColContractLoading(false))
			dispatch(eColContractsActions.toggleError(error))
			message.error(
				'Ошибка отправки данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(eColContractsActions.setEColContractLoading(false))
		}
	}
}

export const revokeTradeUnion = (externalId, isEmployee) => {
	return async (dispatch) => {
		try {
			dispatch(eColContractsActions.setEColContractLoading(true))
			const response = await api.colContracts.revoke(externalId, isEmployee)
			if (response === '') {
				dispatch(eColContractsActions.setEColContractSuccess(true))
				message.success('Договор отозван', 10)
			}
		} catch (error) {
			dispatch(eColContractsActions.setEColContractLoading(false))
			dispatch(eColContractsActions.toggleError(error))
			message.error(
				'Ошибка отправки данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(eColContractsActions.setEColContractLoading(false))
		}
	}
}

export const generateTradeUnionXML = (externalId, isEmployee) => {
	return async (dispatch) => {
		try {
			dispatch(eColContractsActions.setEColContractLoading(true))
			const contractXml = await api.colContracts.getTradeUnionSignedXml(externalId, isEmployee)
			dispatch(eColContractsActions.setEColXML(contractXml))
		} catch (error) {
			dispatch(eColContractsActions.toggleError(error))
			message.error(
				'Ошибка получения данных: ' +
					(error && error.response && error.response.data ? error.response.data.message : ' Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(eColContractsActions.setEColContractLoading(false))
		}
	}
}

export const sendSignedColContract = (isEmployee, signedXml) => {
	return async (dispatch) => {
		try {
			dispatch(eColContractsActions.setEColContractLoading(true))
			const response = await api.colContracts.sendTradeUnionSignedXml(isEmployee, signedXml)
			dispatch(eColContractsActions.setEColContract(response))
			dispatch(eColContractsActions.setEColContractSuccess(true))
			message.success('Данные успешно сохранены', 10)
		} catch (error) {
			dispatch(eColContractsActions.setEColContractLoading(false))
			dispatch(eColContractsActions.toggleError(error))
			message.error(
				'Ошибка отправки данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(eColContractsActions.setEColContractLoading(false))
		}
	}
}
