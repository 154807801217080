import React, { useMemo, useRef, useState, useCallback, useEffect } from 'react'
import { Calendar } from 'react-date-range'
import { format, parse, isValid } from 'date-fns'
import ruLocale from 'date-fns/locale/ru'
import kzLocale from 'date-fns/locale/kk'
import { getCurrentLanguage } from '@/i18next'
import { Calendar as CalendarIcon } from '@app/images'
import { useToggle, useClickOutside } from '@/shared'
import { Icon, Button } from '@/ui'
import styles from './DatePicker.module.scss'
import { useTranslation } from 'react-i18next'

export const DatePicker = ({
	value = '',
	dateFormat = 'dd.MM.yyyy',
	onChange = function () {},
	placeholder,
	showIcon = true,
	inputClassName = '',
	wrapperClasses = '',
	iconClasses = '',
	minDate,
	maxDate,
	error,
	disabled = false,
	...props
}) => {
	const [isOpen, toggleOpen] = useToggle()
	const [textValue, setTextValue] = useState(value || '')
	const datePickerRef = useRef(null)
	const lang = getCurrentLanguage()
	const { t } = useTranslation()

	const inputRef = useRef(null)

	const onChangeValue = useCallback(
		(value) => {
			const date = format(value, dateFormat)
			setTextValue(date)
			onChange(date)
			toggleOpen(false)
		},
		[dateFormat, onChange, toggleOpen]
	)

	const onToggleOpen = useCallback(
		(e) => {
			e.stopPropagation()
			!disabled && toggleOpen()
		},
		[toggleOpen, disabled]
	)

	const onClickOutside = useCallback(
		(event) => {
			event.target.blur()
			toggleOpen()
		},
		[toggleOpen]
	)

	const handleInputClick = useCallback(() => !isOpen && toggleOpen(), [isOpen, toggleOpen])

	const onTypeDate = useCallback(
		(event) => {
			let { value } = event.target

			// Очищаем значение от недопустимых символов, оставляем только цифры и точки
			value = value.replace(/[^0-9.]/g, '')

			// Добавляем точку после дня и месяца, если они введены
			if (value.length === 2 || value.length === 5) {
				value += '.'
			}

			setTextValue(value)

			// Проверяем, если поле ввода пустое, то вызываем onChange с пустой строкой
			if (value === '') {
				onChange('')
				return
			}

			// Проверяем, если год больше 2100, обрезаем его до 2100
			if (value.length >= 10) {
				let year = parseInt(value.slice(6, 10), 10)

				// Проверяем, если год меньше 1900, устанавливаем его на 1900
				if (year < 1900) {
					value = value.slice(0, 6) + '1900' + value.slice(10)
					setTextValue(value)
				} else if (year > 2100) {
					value = value.slice(0, 6) + '2100' + value.slice(10)
					setTextValue(value)
				}

				const date = parse(value, dateFormat, new Date())
				if (isValid(date)) {
					onChange(format(date, dateFormat))
				}
			}
		},
		[dateFormat, onChange]
	)

	const onBackspace = useCallback(
		(event) => {
			if (event.key === 'Backspace') {
				let { value } = event.target

				// Удаляем последний символ
				value = value.slice(0, -1)

				// Если удаляем точку, то удаляем предыдущий символ тоже
				if (value.endsWith('.')) {
					value = value.slice(0, -1)
				}

				setTextValue(value)

				// Проверяем, если поле ввода пустое, то вызываем onChange с пустой строкой
				if (value === '') {
					onChange('')
					event.preventDefault()
				}
			}
		},
		[onChange]
	)

	const formattedDate = useMemo(() => (value ? parse(value, dateFormat, new Date()) : null), [dateFormat, value])

	useClickOutside(datePickerRef, isOpen, onClickOutside)

	useEffect(() => !value && setTextValue(''), [value])

	const width = useMemo(() => datePickerRef?.current?.offsetWidth, [datePickerRef?.current?.offsetWidth])

	return (
		<div className={`${styles.wrapper} ${wrapperClasses} ${disabled ? styles.disabled : ''}`} ref={datePickerRef}>
			<input
				className={`${styles.input} ${inputClassName}`}
				type="text"
				value={textValue}
				onChange={onTypeDate}
				onKeyDown={onBackspace}
				onClick={handleInputClick}
				placeholder={placeholder || t('default_date_value')}
				disabled={disabled}
				ref={inputRef}
				autoComplete="off"
				{...props}
			/>
			{showIcon && (
				<Button type="bare" onClick={onToggleOpen}>
					<Icon src={CalendarIcon} classes={iconClasses} />
				</Button>
			)}
			{isOpen && (
				<Calendar
					locale={lang === 'rus' ? ruLocale : kzLocale}
					editableDateInputs={true}
					className={`${styles.calendar}`}
					onChange={onChangeValue}
					date={formattedDate}
					minDate={minDate}
					maxDate={maxDate}
					disabled={disabled}
					style={{ width: width && width > 300 ? width : 300 }}
				/>
			)}
		</div>
	)
}
