import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getCurrentLanguage } from '@/i18next'
import styles from './MembersTable.module.scss'
import { headCells } from './membersTable.headings'
import DropDownMenu from '@/components/DropdownMenu/DropdownMenu'
import { colorsMembers, statusMembers } from './utils'
import onActionMenuClickMembers from './onActionMenuClickMembers'
import { profUnionsThunks } from '@/entity'
import { useDispatch } from 'react-redux'

export const MembersTable = ({ onToggleAction, tradeUnion, members, isEmployee, page, pageSize, isApproved }) => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	const dispatch = useDispatch()
	const [actionMenuItems, setActionMenuItems] = useState([])

	const tableHeader = useMemo(
		() => (
			<>
				{headCells.map((head, index) => (
					<th key={index + 'thead-th'}>{t(head.label)}</th>
				))}
			</>
		),
		[t]
	)

	const onActionMenuChoose = (mode, row) => {
		switch (mode) {
			case 'exclude':
				dispatch(profUnionsThunks.deleteMembers(tradeUnion.externalId, page, pageSize, isEmployee, row.iin))
				break
			case 'decline':
				dispatch(profUnionsThunks.rejectMembers(tradeUnion.externalId, page, pageSize, row.iin, isEmployee))
				break
			case 'approve':
				dispatch(profUnionsThunks.approveMembers(tradeUnion.externalId, row.iin, isEmployee))
				break
			default:
		}
		onToggleAction(mode, true)
	}

	const getTableRow = useCallback(
		(row, index) => (
			<tr key={index}>
				<td>{row.dateIn}</td>
				<td>{row.iin}</td>
				<td>{row.fio}</td>
				<td>
					<span style={{ color: colorsMembers[row.status] }}>{t(statusMembers[row.status]) || '-'}</span>
				</td>
				<td onClick={(e) => onActionMenuClickMembers(row, e, setActionMenuItems, t, isApproved)}>
					<DropDownMenu
						title={t('select_pls')}
						items={actionMenuItems}
						onItemClick={(mode) => onActionMenuChoose(mode, row)}
					/>
				</td>
			</tr>
		),
		[actionMenuItems, lang, onActionMenuChoose, t]
	)

	const tableBody = useMemo(() => members?.map((row, index) => getTableRow(row, index)), [members, getTableRow])

	return (
		<table className={styles.table}>
			<thead>
				<tr>{tableHeader}</tr>
			</thead>
			<tbody>{tableBody}</tbody>
		</table>
	)
}
