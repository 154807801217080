import React, { useEffect, useState } from 'react'
import { Title } from '@/ui'
import { useTranslation } from 'react-i18next'
import styles from './GeneralInformation.module.scss'
import { Info, InfoGreen, InsertRowAbove } from '@app/icons'
import Wrapper from './component/Wrapper'
import { useSelector } from 'react-redux'
import { employerProfileSelectors } from '@features/employerProfile/model'
import { getWorkerWelfare } from '@/shared/api/rest/personalAffairs'
import { LoadingBlocker } from '@/components/Loader'
import { WorkerWelfare } from './GeneralInformationModel'
import { Tooltip } from 'antd'
const GeneralInformation = ({ setActiveTab }) => {
	const { t } = useTranslation()
	const [welfare, setWorkerWelfare] = useState(WorkerWelfare)
	const activeCompany = useSelector(employerProfileSelectors.activeCompany)
	const [loading, setLoading] = useState(true)
	useEffect(async () => {
		try {
			setWorkerWelfare(await getWorkerWelfare(activeCompany.bin))
		} catch (error) {
			setLoading(false)
		} finally {
			setLoading(false)
		}
	}, [])
	const calculatePercentage = (value) => {
		const percentage = (value / welfare.allEmployeeQuantity) * 100
		return percentage.toFixed(0) + '%'
	}
	return (
		<>
			{loading && <LoadingBlocker />}
			{welfare.bin ? (
				<section>
					<Title>{t('welfare.general')}</Title>
					<div className={styles.row}>
						<Wrapper title={welfare.allEmployeeQuantity} description={t('welfare.totalEmployees')}></Wrapper>
						<Wrapper
							title={welfare.manEmployeeQuantity + ' / ' + welfare.womanEmployeeQuantity}
							description={t('welfare.menWomen')}
						></Wrapper>
						<div className={styles.information}>
							<InfoGreen className={styles.hintIcon} />
							<div className={styles.text}>
								{t('welfare.calculated')}: {welfare.actualDate}
							</div>
						</div>
					</div>
					<Title>{t('welfare.structure')}</Title>
					<div className={styles.row}>
						<Wrapper
							title={welfare.pensionerQantity}
							description={t('welfare.pensioners')}
							percent={calculatePercentage(welfare.pensionerQantity)}
						></Wrapper>
						<Wrapper
							title={welfare.largeFamiliesEmployeeQuantity}
							description={t('welfare.largeFamilies')}
							percent={calculatePercentage(welfare.largeFamiliesEmployeeQuantity)}
						></Wrapper>
						<Wrapper
							title={welfare.disabledEmployeeQuantity}
							description={t('welfare.disabilities')}
							percent={calculatePercentage(welfare.disabledEmployeeQuantity)}
						></Wrapper>
						<Wrapper
							title={welfare.guardiansEmployeeQantity}
							description={t('welfare.guardians')}
							percent={calculatePercentage(welfare.guardiansEmployeeQantity)}
						></Wrapper>
					</div>
					<div className={styles.row}>
						<Wrapper
							title={welfare.dregEmployeeQuantity}
							description={t('welfare.chronic')}
							percent={calculatePercentage(welfare.dregEmployeeQuantity)}
						></Wrapper>
						<Wrapper
							title={welfare.aspEmployeeQuantity}
							description={t('welfare.recipients')}
							percent={calculatePercentage(welfare.aspEmployeeQuantity)}
						></Wrapper>
						<Wrapper
							title={welfare.harmfullEmployeeQuantity}
							description={t('welfare.hazardous')}
							percent={calculatePercentage(welfare.harmfullEmployeeQuantity)}
						></Wrapper>
						<div className={styles.blockEmpty} />
					</div>
					<Title>{t('welfare.welfare')}</Title>
					<div className={styles.blockTitle}>{t('welfare.incomeSituation')}</div>
					<div className={styles.row}>
						<Wrapper
							title={welfare.belowSubsistenceLevel}
							description={t('welfare.monthlyMinimum')}
							percent={calculatePercentage(welfare.belowSubsistenceLevel)}
						></Wrapper>
						<Wrapper
							title={welfare.belowMinSalary}
							description={t('welfare.minimumWage')}
							percent={calculatePercentage(welfare.belowMinSalary)}
						></Wrapper>
					</div>
					<div className={styles.blockTitle}>
						{t('welfare.lifeSituation')}
						<span className={styles.spanClass} onClick={() => setActiveTab(1)}>
							<InsertRowAbove />
							{t('welfare.tabs.list')}
							<Tooltip
								placement="rightTop"
								color="white"
								overlayInnerStyle={{ color: '#5B6880' }}
								title={t('welfare.tabs.hint')}
							>
								<span>
									<Info className={styles.hintIconGreen} />
								</span>
							</Tooltip>
						</span>
					</div>
					<div className={styles.row}>
						<Wrapper
							title={welfare.dfmCategoryA}
							description={t('welfare.prosperous')}
							percent={calculatePercentage(welfare.dfmCategoryA)}
						></Wrapper>
						<Wrapper
							title={welfare.dfmCategoryB}
							description={t('welfare.satisfactory')}
							percent={calculatePercentage(welfare.dfmCategoryB)}
							color={'purple'}
						></Wrapper>
						<Wrapper
							title={welfare.dfmCategoryC}
							description={t('welfare.dysfunctional')}
							percent={calculatePercentage(welfare.dfmCategoryC)}
							color={'grey'}
						></Wrapper>
						<Wrapper
							title={welfare.dfmCategoryD}
							description={t('welfare.crisis')}
							percent={calculatePercentage(welfare.dfmCategoryD)}
							color={'orange'}
						></Wrapper>
						<Wrapper
							title={welfare.dfmCategoryE}
							description={t('welfare.emergency')}
							percent={calculatePercentage(welfare.dfmCategoryE)}
							color={'red'}
						></Wrapper>
					</div>
				</section>
			) : welfare.bin === null ? (
				<div className={styles.information} style={{ height: '96px' }}>
					<InfoGreen className={styles.hintIcon} />
					<div className={styles.text}>{t('welfare.nodata')}</div>
				</div>
			) : null}
		</>
	)
}

export default GeneralInformation
