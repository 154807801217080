import { handbookThunks } from '@/entity/handbook'
import { getEmployerActivityByIIN, getEmployerActivityByIINAndBin } from '@/shared/api/rest/profile'
import { profUnionsActions } from '@/entity'
import { api } from '@/shared'
import { message } from 'antd'

export const getOptions = () => {
	return (dispatch, getState) => {
		const { unionType, okedsTop, districts } = getState().handbook
		if (!unionType.length > 0) dispatch(handbookThunks.getTradeUnionType())
		if (!okedsTop.length > 0) dispatch(handbookThunks.getOkedsTop())
		if (!districts.length > 0) dispatch(handbookThunks.getDistricts())
	}
}

export const getTradeUnion = (isEmployee) => {
	return async (dispatch) => {
		try {
			dispatch(profUnionsActions.toggleLoading(true))
			const tradeUnion = await api.profUnions.getTradeUnion(isEmployee)
			dispatch(profUnionsActions.setProfUnions(tradeUnion))
			dispatch(profUnionsActions.toggleSuccess(true))
		} catch (error) {
			dispatch(profUnionsActions.toggleSuccess(false))
			message.error('Ошибка получения данных:' + (error.response ? error.response.data.message : 'Неизвестная ошибка'))
		} finally {
			dispatch(profUnionsActions.toggleLoading(false))
		}
	}
}

export const getPerson = (payload) => {
	return async (dispatch) => {
		try {
			dispatch(profUnionsActions.toggleLoadingChiefFio(true))
			const person = await getEmployerActivityByIIN(payload)
			dispatch(profUnionsActions.setSearchChiefFio(person.fullNamePerson))
			dispatch(profUnionsActions.toggleSuccessChiefFio(true))
		} catch (error) {
			dispatch(profUnionsActions.toggleSuccessChiefFio(false))
			message.error('Ошибка получения данных:' + (error.response ? error.response.data.message : 'Неизвестная ошибка'))
		} finally {
			dispatch(profUnionsActions.toggleLoadingChiefFio(false))
		}
	}
}

export const getEmployerProfUnitsUnionName = (payload) => {
	return async (dispatch) => {
		try {
			dispatch(profUnionsActions.toggleLoadingBin(true))
			const employer = await getEmployerActivityByIINAndBin(payload)
			dispatch(profUnionsActions.setSearchName(employer.fullNameRu))
			dispatch(profUnionsActions.toggleSuccessBin(true))
		} catch (error) {
			dispatch(profUnionsActions.toggleSuccessBin(false))
			message.error('Ошибка получения данных:' + (error.response ? error.response.data.message : 'Неизвестная ошибка'))
		} finally {
			dispatch(profUnionsActions.toggleLoadingBin(false))
		}
	}
}

export const getEmployerProfParent = (payload) => {
	return async (dispatch) => {
		try {
			dispatch(profUnionsActions.toggleLoadingBinParent(true))
			const employer = await getEmployerActivityByIINAndBin(payload)
			dispatch(profUnionsActions.setSearchParentName(employer.fullNameRu))
			dispatch(profUnionsActions.toggleSuccessBinParent(true))
		} catch (error) {
			dispatch(profUnionsActions.toggleSuccessBinParent(false))
			message.error('Ошибка получения данных:' + (error.response ? error.response.data.message : 'Неизвестная ошибка'))
		} finally {
			dispatch(profUnionsActions.toggleLoadingBinParent(false))
		}
	}
}

export const getEmployerProfUnitsEmployerBin = (payload) => {
	return async (dispatch) => {
		try {
			dispatch(profUnionsActions.toggleLoadingBinEmployer(true))
			const employer = await getEmployerActivityByIINAndBin(payload)
			dispatch(profUnionsActions.setSearchEmployerName(employer.fullNameRu))
			dispatch(profUnionsActions.toggleSuccessBinEmployer(true))
		} catch (error) {
			dispatch(profUnionsActions.toggleSuccessBinEmployer(false))
			message.error('Ошибка получения данных:' + (error.response ? error.response.data.message : 'Неизвестная ошибка'))
		} finally {
			dispatch(profUnionsActions.toggleLoadingBinEmployer(false))
		}
	}
}

export const getXml = (data) => {
	return async (dispatch) => {
		try {
			dispatch(profUnionsActions.toggleLoading(true))
			const xml = await api.profUnions.getXml(data)
			dispatch(profUnionsActions.toggleSuccess(true))
			dispatch(profUnionsActions.setProfUnionsXml(xml))
		} catch (error) {
			dispatch(profUnionsActions.toggleSuccess(false))
			dispatch(profUnionsActions.resetXml())
			message.error(
				'Ошибка получения данных для подписи:' + (error.response ? error.response.data.message : 'Неизвестная ошибка')
			)
		} finally {
			dispatch(profUnionsActions.toggleLoading(false))
		}
	}
}

export const sendSignedProfUnions = (data, isEmployee, myTradeUnion, page = 0, size = 10) => {
	return async (dispatch) => {
		try {
			dispatch(profUnionsActions.toggleLoading(true))
			const response = await api.profUnions.sendSignedProfUnions({ data }, isEmployee, myTradeUnion)
			dispatch(profUnionsActions.setProfUnions(response))
			dispatch(profUnionsActions.toggleSuccess(true))
			message.success('Данные успешно отправлены')
			const tradeUnionList = await api.profUnions.getTradeUnionList({ page, size })
			dispatch(profUnionsActions.setProfUnionsList(tradeUnionList))
		} catch (error) {
			dispatch(profUnionsActions.toggleSuccess(false))
			message.error(
				'Ошибка отправки данных:' + (error.response ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(profUnionsActions.toggleLoading(false))
		}
	}
}

export const getProfUnionsList = (page, size) => {
	return async (dispatch) => {
		try {
			dispatch(profUnionsActions.toggleLoading(true))
			const response = await api.profUnions.getTradeUnionList({ page, size })
			dispatch(profUnionsActions.setProfUnionsList(response))
			dispatch(profUnionsActions.toggleSuccess(true))
		} catch (error) {
			dispatch(profUnionsActions.toggleSuccess(false))
			message.error('Ошибка получения данных: ' + (error.response ? error.response.data.message : 'Неизвестная ошибка'))
		} finally {
			dispatch(profUnionsActions.toggleLoading(false))
		}
	}
}

export const getByOrgContracts = () => {
	return async (dispatch) => {
		try {
			dispatch(profUnionsActions.toggleLoading(true))
			const response = await api.profUnions.getTradeUnionEmployeeList()
			dispatch(profUnionsActions.setMyUnions(response))
			dispatch(profUnionsActions.toggleSuccess(true))
		} catch (error) {
			dispatch(profUnionsActions.toggleSuccess(false))
			dispatch(profUnionsActions.toggleError(error))
			message.error('Ошибка получения данных: ' + (error.response ? error.response.data.message : 'Неизвестная ошибка'))
		} finally {
			dispatch(profUnionsActions.toggleLoading(false))
		}
	}
}

export const getUnionTrade = (externalId) => {
	return async (dispatch) => {
		try {
			dispatch(profUnionsActions.toggleLoading(true))
			const unionTrade = await api.profUnions.getUnionTrade(externalId)
			dispatch(profUnionsActions.setProfUnions(unionTrade))
			dispatch(profUnionsActions.toggleSuccess(true))
		} catch (error) {
			dispatch(profUnionsActions.toggleSuccess(false))
			message.error('Ошибка получения данных:' + (error.response ? error.response.data.message : 'Неизвестная ошибка'))
		} finally {
			dispatch(profUnionsActions.toggleLoading(false))
			dispatch(profUnionsActions.toggleSuccess(false))
		}
	}
}

export const approveTradeUnion = (externalId) => {
	return async (dispatch) => {
		try {
			dispatch(profUnionsActions.toggleLoading(true))
			const response = await api.profUnions.approveTradeUnion(externalId)
			dispatch(profUnionsActions.setProfUnions(response))
			dispatch(profUnionsActions.toggleSuccess(true))
			message.success('Профсоюз успешно подтвержден')
			let page = 0,
				size = 10
			const tradeUnionList = await api.profUnions.getTradeUnionList({ page, size })
			dispatch(profUnionsActions.setProfUnionsList(tradeUnionList))
		} catch (error) {
			dispatch(profUnionsActions.toggleSuccess(false))
			message.error('Ошибка получения данных:' + (error.response ? error.response.data.message : 'Неизвестная ошибка'))
		} finally {
			dispatch(profUnionsActions.toggleLoading(false))
		}
	}
}

export const getTradeUnionMembers = (externalId, page = 0, size = 10) => {
	return async (dispatch) => {
		try {
			dispatch(profUnionsActions.toggleLoading(true))
			const response = await api.profUnions.getMembers(externalId, { page, size })
			if (response.content) {
				dispatch(profUnionsActions.setMembersProfUnions(response))
			}
			dispatch(profUnionsActions.toggleSuccess(true))
		} catch (error) {
			dispatch(profUnionsActions.toggleSuccess(false))
			message.error(
				'Ошибка получения членов профсоюза:' + (error.response ? error.response.data.message : 'Неизвестная ошибка')
			)
		} finally {
			dispatch(profUnionsActions.toggleLoading(false))
		}
	}
}

export const addMembers = (externalId, isEmployee, data) => {
	return async (dispatch) => {
		try {
			dispatch(profUnionsActions.toggleLoading(true))
			const response = await api.profUnions.addMembers(externalId, { isEmployee }, data)
			dispatch(profUnionsActions.setNewMembersProfUnion(response))
			dispatch(profUnionsActions.toggleSuccess(true))
			message.success('Успешно добавлено')
		} catch (error) {
			dispatch(profUnionsActions.toggleSuccess(false))
			message.error('Ошибка добавления: ' + (error.response ? error.response.data.message : 'Неизвестная ошибка'))
		} finally {
			dispatch(profUnionsActions.toggleLoading(false))
		}
	}
}

export const deleteMembers = (externalId, page = 1, pageSize = 10, isEmployee, iins) => {
	return async (dispatch) => {
		try {
			dispatch(profUnionsActions.toggleLoading(true))
			const response = await api.profUnions.deleteMembers(externalId, page, pageSize, isEmployee, [iins])
			if (response) {
				dispatch(profUnionsActions.setMembersProfUnions(response))
			}
			dispatch(profUnionsActions.toggleSuccess(true))
			message.success('Успешно исключён')
		} catch (error) {
			dispatch(profUnionsActions.toggleSuccess(false))
			message.error('Ошибка исключения: ' + (error.response ? error.response.data.message : 'Неизвестная ошибка'))
		} finally {
			dispatch(profUnionsActions.toggleLoading(false))
		}
	}
}

export const rejectMembers = (externalId, page = 0, size = 10, iin, isEmployee) => {
	return async (dispatch) => {
		try {
			dispatch(profUnionsActions.toggleLoading(true))
			const response = await api.profUnions.rejectMembers(externalId, (page = 0), (size = 10), iin, isEmployee)
			if (response) {
				dispatch(profUnionsActions.setMembersProfUnions(response))
			}
			dispatch(profUnionsActions.toggleSuccess(true))
			message.success('Успешно выполненно')
		} catch (error) {
			dispatch(profUnionsActions.toggleSuccess(false))
			message.error('Ошибка выполнения: ' + (error.response ? error.response.data.message : 'Неизвестная ошибка'))
		} finally {
			dispatch(profUnionsActions.toggleLoading(false))
		}
	}
}

export const approveMembers = (externalId, iin, isEmployee, page = 0, size = 10) => {
	return async (dispatch) => {
		try {
			dispatch(profUnionsActions.toggleLoading(true))
			const response = await api.profUnions.approveMembers(externalId, iin, isEmployee)
			if (response === '') {
				dispatch(profUnionsActions.setMemberStatus(iin, 'APPROVED'))
			}
			dispatch(profUnionsActions.toggleSuccess(true))
			message.success('Успешно выполненно')
		} catch (error) {
			message.error('Ошибка выполнения: ' + (error.response ? error.response.data.message : 'Неизвестная ошибка'))
		} finally {
			dispatch(profUnionsActions.toggleLoading(false))
		}
	}
}

export const addSelf = (externalId, person) => {
	return async (dispatch) => {
		try {
			dispatch(profUnionsActions.toggleLoading(true))
			const response = await api.profUnions.addSelf(externalId, person)
			dispatch(profUnionsActions.toggleSuccess(true))
			message.success('Запрос успешно отправлен')
		} catch (error) {
			dispatch(profUnionsActions.toggleSuccess(false))
			message.error('Ошибка добавления: ' + (error.response ? error.response.data.message : 'Неизвестная ошибка'))
		} finally {
			dispatch(profUnionsActions.toggleLoading(false))
			dispatch(profUnionsActions.toggleSuccess(false))
		}
	}
}
