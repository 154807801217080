import React, { useMemo } from 'react'
import { Context } from './Context'
import { RenderTableColumns } from './RenderTableColumns'
import styles from './Table.module.scss'

export const Table = ({ children, data, tableName = 'table', emptyPlaceholder = '' }) => {
	const contextValue = useMemo(
		() => ({
			data,
			tableName,
			emptyPlaceholder,
		}),
		[data, emptyPlaceholder, tableName]
	)

	return (
		<table className={styles.table}>
			<Context.Provider value={contextValue}>
				<RenderTableColumns>{children}</RenderTableColumns>
			</Context.Provider>
		</table>
	)
}
