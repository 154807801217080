import React, { useEffect } from 'react'
import styles from './Sidebar.module.scss'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { sideBarActions } from '@/entity/sideBar'
import { useLocation } from 'react-router-dom'

export const CollapsibleMenu = ({ title, icon, className, children }) => {
	const dispatch = useDispatch()
	const location = useLocation()
	const { t } = useTranslation()
	const isOpen = useSelector((state) => state.sideBar.openedChildsIds.includes(title))

	useEffect(() => {
		if (!children) return

		if (children instanceof Array) {
			for (const child of children) {
				if (child.props?.to === location.pathname) {
					dispatch(sideBarActions.addOpenedChildId(title))
					break
				}
			}
		} else {
			if (children.props?.to === location.pathname) {
				dispatch(sideBarActions.addOpenedChildId(title))
			}
		}
	}, [])

	const handleToggleClick = (e) => {
		e.stopPropagation()
		if (title?.length > 0) {
			if (isOpen) {
				dispatch(sideBarActions.removeOpenedChildId(title))
			} else {
				dispatch(sideBarActions.addOpenedChildId(title))
			}
		}
	}
	return (
		<div>
			<a className={classNames(styles.item, styles.item__group, className)} onClick={handleToggleClick}>
				<div className={styles.item__title}>
					{icon}
					<p>{t(title)}</p>
				</div>
				<svg
					fill="currentColor"
					className={classNames(styles.arrow, isOpen ? '' : styles.arrow__opened)}
					focusable="false"
					aria-hidden="true"
					viewBox="0 0 24 24"
					data-testid="KeyboardArrowUpIcon"
				>
					<path d="M7.41 15.41 12 10.83l4.59 4.58L18 14l-6-6-6 6z"></path>
				</svg>
			</a>

			{isOpen && <div className={styles.item__children}>{children}</div>}
		</div>
	)
}
