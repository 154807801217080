import React, { useCallback, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Field as FormikField, Form as FormikForm, useFormikContext } from 'formik'
import { formFieldNames } from '@/entity'
import { Title, Field, Button } from '@/ui'
import { getKatoChildren } from '@/shared/api/rest/shared'
import styles from './ContractForm.module.scss'
import { request } from '@/shared/api/request'
import { ContractPosition } from '@/features/contracts/registrationEmployerContract/form/conditions/ContractPosition'
import { message, Tooltip } from 'antd'
import {
	WrappedCheckbox,
	RegionField,
	DistrictField,
	CountryField,
	RemoteWorkField,
	TariffRateField,
	LocalityFieldContract,
} from '@features/contracts/registrationEmployerContract/form/fields'
import { EndDateField } from '@features/contracts/registrationEmployerContract/form/fields/EndDateField'
import { Info } from '@app/icons'
import { WorkingConditions } from '@features/contracts/registrationEmployerContract/form/workingСonditions/workingConditions'
import { usePrevious } from '@/shared'
import { ConfirmDocument } from '@/components/AddDocuments/ConfirmDocument'
import { ContractDuration } from '@/entity/contracts/ui/form/components/ContractDuration'
import { filterKato, filterLeafItems } from '@/entity/handbook/thunks'
import { useSelector } from 'react-redux'
import { employerProfileSelectors } from '@features/employerProfile/model'

export const Form = ({
	options = {},
	advanced,
	onCancel,
	isEdit,
	isElectronicButton = false,
	onOpenTemplateModal,
	specSkillsListMessage,
	skillsListMessage,
	_onSubmit,
	isFormDirty,
	setFormDirty,
	professionalSkills,
	personalQualities,
	attachProjectFile,
	attachHarmfulConditionsFiles,
	files,
}) => {
	const { t } = useTranslation()
	const [regions, setRegions] = useState([])
	const [localities, setLocalities] = useState([])
	const [documentList, setDocList] = useState([])
	const [disableErrorSize, setDisableErrorSize] = useState(true)
	const [checkDoc, setCheckDoc] = useState(true)

	const {
		contractTerms = [],
		positionsByKnz = [],
		positionsByKnzFull = [],
		positions = [],
		jobTypes = [],
		workingTimeModes = [],
		countries = [],
		districts = [],
		remoteWorkTypes = [],
		workCondition = [],
		workActivityTypeGroups = {
			list1: [],
			list2: [],
			list3: [],
		},
		nonresidentAttractingBasis,
		nonresidentEducation,
	} = options

	const [filteredBasis, setFilteredBasis] = useState(nonresidentAttractingBasis)
	const [immigrant, setImmigrant] = useState(false)
	const [loadCalendar, setLoadCalendar] = useState(true)
	const activeCompany = useSelector(employerProfileSelectors.activeCompany)
	const { flEmployer } = activeCompany
	const { resetForm, setFieldValue, values, submitForm, isSubmitting, validateForm, setTouched, errors, ...form } =
		useFormikContext()

	const { dworkingConditionsCode, isResident, foreignLaborInfo } = values
	const [isError, setIsError] = useState(false)
	const getDocument = (docList) => {
		setDocList(docList)
		attachHarmfulConditionsFiles(docList)
	}
	const getErrorSize = (getErrorSize) => {
		setDisableErrorSize(getErrorSize)
	}
	const [isElectronicCheckboxEditable, setIsElectronicCheckboxEditable] = useState(true)

	useEffect(() => {
		if (isElectronicButton) {
			setIsElectronicCheckboxEditable(false)
			setFieldValue('isElectronic', true)
		}
	}, [isElectronicButton])

	const loadRegions = useCallback(async (code) => {
		if (code) {
			const response = await filterKato(code)
			setRegions(response)
		} else {
			setRegions([])
		}
	}, [])

	const refreshLocalities = useCallback(() => setLocalities([]), [])

	const loadLocalities = useCallback(async (code, onlyLeaves) => {
		if (code) {
			const response = await filterLeafItems(code)
			setLocalities(response)
		}
	}, [])

	const getFlData = useCallback(
		(iin) => request({ url: '/contract/universalMethods/requestFlData?iin=' + iin, method: 'GET' }),
		[]
	)

	const createFromTemplate = async () => {
		setFormDirty(true)
		const result = await validateForm()
		if (Object.values(result).length === 0) {
			// Фильтрация данных, исключая ненужные поля
			const excludedFields = ['requiredList', 'dpositionCodeList', 'positionParents']

			let filteredData = Object.keys(values)
				.filter((key) => !excludedFields.some((excludedFields) => key.includes(excludedFields)))
				.reduce((obj, key) => {
					obj[key] = values[key]
					return obj
				}, {})
			onOpenTemplateModal(filteredData)
		}
	}

	const handleAttachFile = async () => {
		setFormDirty(true)
		const result = await validateForm()
		if (Object.values(result).length === 0) attachProjectFile(values)
	}

	const filtredPositions = positions?.filter((item) => item.isLast) || []

	const formatDate = (inputDate) => {
		const [year, month, day] = inputDate.split('-')
		return `${day}.${month}.${year}`
	}

	useEffect(() => {
		if (dworkingConditionsCode && dworkingConditionsCode !== '0') {
			setCheckDoc(documentList.length >= 1 && !disableErrorSize)
		} else {
			setCheckDoc(true)
		}
	}, [documentList, disableErrorSize, dworkingConditionsCode])

	const prevValue = usePrevious(values.dateFrom)
	useEffect(() => {
		if (prevValue) {
			setFieldValue('positionParents', '')
			setFieldValue('dpositionCode', null)
		}
	}, [values.dateFrom])

	useEffect(() => {
		if (flEmployer && isResident === false) {
			setFilteredBasis(nonresidentAttractingBasis.filter((item) => ['001-0001', '001'].includes(item.code)))
			setImmigrant(true)
		} else {
			setImmigrant(false)
		}
	}, [activeCompany, isResident, nonresidentAttractingBasis])

	useEffect(() => {
		//Определение ИРС или Иммигрант
		const { nonResidentPermitNumber } = foreignLaborInfo
		const permitStartsWithKZ = nonResidentPermitNumber && nonResidentPermitNumber.includes('KZ')
		if (!flEmployer && isResident === false && foreignLaborInfo.nonResidentPermitNumber !== null) {
			setFieldValue('isForeignLabor', permitStartsWithKZ)
			setIsError(!permitStartsWithKZ)
		} else {
			setFieldValue('isForeignLabor', false)
			setIsError(false)
		}
		if (flEmployer && isResident === false && foreignLaborInfo.nonResidentPermitNumber !== null) {
			setIsError(permitStartsWithKZ)
		} else if (flEmployer) {
			setIsError(false)
		}
	}, [activeCompany, isResident, nonresidentAttractingBasis, foreignLaborInfo.nonResidentPermitNumber])

	//В пределах срока ИРС
	useEffect(() => {
		if (immigrant) {
			setFieldValue(formFieldNames.validityTerm, '9')
		}
	}, [immigrant])

	useEffect(() => {
		if (values.foreignLaborInfo.isMigration === 1) {
			setLoadCalendar(false)
			setTimeout(() => {
				setLoadCalendar(true)
			}, 100)
		}
	}, [values[formFieldNames.startContractDate], values[formFieldNames.endContractDate]])

	return (
		<FormikForm className={styles.form}>
			{advanced && (
				<>
					<section>
						<div className={styles.item}>
							<Title>{t('employer_data')}</Title>
							<div className={styles.fields}>
								<FormikField name={formFieldNames.bin}>
									{({ field }) => <Field type="text" readOnly label={`${t('bin')}/${t('yin')}`} {...field} />}
								</FormikField>
								<FormikField name={formFieldNames.enterpriseName}>
									{({ field }) => <Field type="text" readOnly label={t('enterprise_name')} {...field} />}
								</FormikField>
							</div>
						</div>
					</section>
					<section>
						<div className={styles.item}>
							<Title>{t('employee_data')}</Title>
							<div className={styles.fields}>
								<FormikField name={formFieldNames.iin}>
									{({ field, meta: { touched, error } }) => (
										<Field
											type="number"
											hideNumberArrows
											isRequired
											label={t('employee_yin')}
											placeholder={t('enter_count_digitals', { count: 12 })}
											error={(touched || isFormDirty) && error}
											{...field}
											maxLength={12}
										/>
									)}
								</FormikField>
								<div className={styles.fieldAndButton}>
									<Button
										type="button"
										onClick={() => {
											getFlData(values[formFieldNames.iin]).then((value) => {
												if (value.fullNamePerson !== null) {
													let oldMigrationValue = values.foreignLaborInfo?.isMigration
													setFieldValue(formFieldNames.employeeInitials, value.fullNamePerson || '')
													setFieldValue('isResident', value.isResident)
													setFieldValue('surname', value.surname)
													setFieldValue('name', value.firstname)
													setFieldValue('middlename', value.patronname)
													setFieldValue('foreignLaborInfo.isMigration', value.isMigration)
													if (value.isMigration === 1) {
														if (value.nonresidentPermitDate)
															setFieldValue('foreignLaborInfo.nonResidentPermitDate', value.nonresidentPermitDate)
														if (value.nonresidentPermitNumber)
															setFieldValue('foreignLaborInfo.nonResidentPermitNumber', value.nonresidentPermitNumber)
														if (value.nonresidentBeginDate) {
															setFieldValue(formFieldNames.startContractDate, formatDate(value.nonresidentBeginDate))
														}
														if (value.nonresidentEndDate)
															setFieldValue(formFieldNames.endContractDate, formatDate(value.nonresidentEndDate))
													} else if (oldMigrationValue === 1) {
														setFieldValue('foreignLaborInfo.nonResidentPermitDate', null)
														setFieldValue('foreignLaborInfo.nonResidentPermitNumber', null)
														setFieldValue(formFieldNames.startContractDate, null)
														setFieldValue(formFieldNames.endContractDate, null)
													}
												} else message.error(t('person_not_found'), 5)
											})
										}}
									>
										{t('find')}
									</Button>
									<FormikField name={formFieldNames.employeeInitials}>
										{({ field, meta: { touched, error } }) => (
											<Field
												type="text"
												isRequired
												disabled
												label={t('employee_initials')}
												placeholder={t('filled_automate')}
												{...field}
												error={(touched || isFormDirty) && error}
											/>
										)}
									</FormikField>
								</div>
								{isError ? (
									<p className={styles.error}>
										{flEmployer ? t('contracts_tab.error_irs') : t('contracts_tab.error_immigrant')}
									</p>
								) : null}
							</div>
						</div>
					</section>
				</>
			)}
			{isResident === false && (
				<section style={{ marginTop: '-25px' }}>
					<div className={styles.item}>
						<ContractDuration
							nonresidentAttractingBasis={filteredBasis}
							nonresidentEducation={nonresidentEducation}
							isFormDirty={isFormDirty}
							activeCompany={activeCompany}
						/>
					</div>
				</section>
			)}
			<section>
				<div className={styles.item}>
					<div className={`${styles.fields} ${styles.fields_verticalCenter}`}>
						<Title>{t('contracts_tab.information_about_contract')}</Title>
						{isElectronicButton ? (
							<div className={styles.label}>
								<FormikField name={formFieldNames.electronicEmploymentContract}>
									{({ field }) => (
										<WrappedCheckbox
											disabled={!isElectronicCheckboxEditable}
											{...field}
											label={t('electronic_employment_contract')}
										/>
									)}
								</FormikField>
							</div>
						) : (
							<div className={styles.labelHint}>
								<FormikField name={formFieldNames.electronicEmploymentContract}>
									{({ field }) => (
										<WrappedCheckbox
											disabled={!isElectronicCheckboxEditable}
											{...field}
											label={t('electronic_employment_contract')}
										/>
									)}
								</FormikField>
								<Tooltip
									placement="rightTop"
									color="white"
									overlayInnerStyle={{ color: '#5B6880', width: '320px' }}
									title={t('electronic_hint_contract')}
								>
									<span>
										<Info className={styles.hintIcon} />
									</span>
								</Tooltip>
							</div>
						)}
					</div>
					<div className={styles.fields}>
						<FormikField name={formFieldNames.contractEmployerNumber}>
							{({ field, meta: { touched, error, errors } }) => (
								<>
									<Field
										type="text"
										label={t('contracts_tab.contract_employer_number')}
										placeholder={t('contracts_tab.indicate_contract_employer_number')}
										isRequired
										error={(touched || isFormDirty) && error}
										{...field}
									/>
									{errors?.['contractNumber'] && <div style={{ color: 'red' }}>{errors?.['contractNumber']}</div>}
								</>
							)}
						</FormikField>
						<FormikField name={formFieldNames.validityTerm}>
							{({ field, meta: { touched, error } }) => (
								<Field
									type="text"
									fieldType="select"
									hint={flEmployer && immigrant ? t('contracts_tab.validity_term_hint') : ''}
									label={t('contracts_tab.validity_term')}
									isRequired
									placeholder={t('choose_from_handbook')}
									options={contractTerms}
									error={(touched || isFormDirty) && error}
									{...field}
									disabled={flEmployer && immigrant}
								/>
							)}
						</FormikField>
					</div>
					{loadCalendar ? (
						<div className={`${styles.fields} ${styles.threeFields}`}>
							<FormikField name={formFieldNames.dateOfContractSigning}>
								{({ field, meta: { touched, error } }) => (
									<Field
										fieldType="datePicker"
										label={t('contracts_tab.date_of_contract_signing')}
										isRequired
										placeholder={t('default_date_value')}
										// minDate={isElectronicButton ? new Date() : new Date(1900, 0, 1)}
										hint={values.isElectronic ? t('electronic_contract.register_date_hint') : ''}
										maxDate={values.isElectronic ? new Date(Date.now() + 1209600000) : new Date()}
										error={(touched || isFormDirty) && error}
										{...field}
									/>
								)}
							</FormikField>
							{isElectronicButton ? (
								<FormikField name={formFieldNames.startContractDate}>
									{({ field, meta: { touched, error } }) => (
										<Field
											fieldType="datePicker"
											label={t('contracts_tab.start_contract_date')}
											isRequired
											placeholder={t('default_date_value')}
											options={contractTerms}
											// minDate={isElectronicButton ? new Date() : new Date(1900, 0, 1)}
											hint={values.isElectronic ? t('electronic_contract.date_from_hint') : ''}
											error={(touched || isFormDirty) && error}
											disabled={values.foreignLaborInfo?.isMigration === 1}
											{...field}
										/>
									)}
								</FormikField>
							) : (
								<FormikField name={formFieldNames.startContractDate}>
									{({ field, meta: { touched, error } }) => (
										<Field
											fieldType="datePicker"
											label={t('contracts_tab.start_contract_date')}
											isRequired
											placeholder={t('default_date_value')}
											options={contractTerms}
											// minDate={isElectronicButton ? new Date() : new Date(1900, 0, 1)}
											maxDate={values.isElectronic ? new Date(Date.now() + 1209600000) : new Date()}
											hint={values.isElectronic ? t('electronic_contract.date_from_hint') : ''}
											error={(touched || isFormDirty) && error}
											disabled={values.foreignLaborInfo?.isMigration === 1}
											{...field}
										/>
									)}
								</FormikField>
							)}
							<div className={styles.wrapper}>
								<EndDateField
									isFormDirty={isFormDirty}
									contractTerms={contractTerms}
									immigrant={immigrant}
									disabled={values.foreignLaborInfo?.isMigration === 1}
								/>
							</div>
						</div>
					) : null}
					<ContractPosition
						positions={filtredPositions}
						positionsByKnz={positionsByKnz}
						workCondition={workCondition}
						positionsByKnzFull={positionsByKnzFull}
						isFormDirty={isFormDirty}
						isElectronicButton={false}
						immigrant={immigrant}
					/>
					<div className={`${styles.fields} ${styles.threeFields}`}>
						<WorkingConditions workActivityTypeGroups={workActivityTypeGroups} isFormDirty={isFormDirty} />
					</div>
					<div className={`${styles.fields} ${styles.threeFields}`}>
						<FormikField name={formFieldNames.jobType}>
							{({ field, meta: { touched, error } }) => (
								<Field
									fieldType="select"
									isRequired
									label={t('contracts_tab.job_type')}
									placeholder={t('choose_from_handbook')}
									options={jobTypes}
									error={(touched || isFormDirty) && error}
									{...field}
								/>
							)}
						</FormikField>
						<FormikField name={formFieldNames.workingTimeMode}>
							{({ field, meta: { touched, error } }) => (
								<Field
									fieldType="select"
									isRequired
									label={t('contracts_tab.working_time_mode')}
									placeholder={t('choose_from_handbook')}
									options={workingTimeModes}
									error={(touched || isFormDirty) && error}
									type="text"
									{...field}
								/>
							)}
						</FormikField>
						<TariffRateField />
					</div>
					<div className={`${styles.fields} ${styles.threeFields}`}>
						<RemoteWorkField remoteWorkTypes={remoteWorkTypes} isFormDirty={isFormDirty} immigrant={immigrant} />
						<CountryField options={countries} isFormDirty={isFormDirty} />
						<DistrictField
							options={districts}
							loadRegions={loadRegions}
							isFormDirty={isFormDirty}
							refreshLocalities={refreshLocalities}
						/>
					</div>
					<div className={`${styles.fields} ${styles.threeFields}`}>
						<RegionField options={regions} loadLocalities={loadLocalities} isFormDirty={isFormDirty} />
						<LocalityFieldContract isFormDirty={isFormDirty} options={localities} />
						<FormikField name={formFieldNames.addressWork}>
							{({ field, meta: { touched, error } }) => (
								<Field
									type="text"
									fieldType="input"
									isRequired={values[formFieldNames.remoteWork] !== '1' || !!values[formFieldNames.countryWork]}
									label={t('contracts_tab.address_work')}
									placeholder={t('contracts_tab.indicate_address_work')}
									error={(touched || isFormDirty) && error}
									{...field}
								/>
							)}
						</FormikField>
					</div>
				</div>
			</section>

			{isElectronicButton && (
				<section>
					<Title>{t('electronic_contract.contract_text_title')}</Title>
					<div className={`${styles.actions} ${styles.actions_electronic}`}>
						<Button onClick={createFromTemplate} disabled={isSubmitting || isError}>
							{t('electronic_contract.create_project_from_template')}
						</Button>
						<Button onClick={handleAttachFile} disabled={isSubmitting || isError}>
							{t('electronic_contract.attach_file')}
						</Button>
					</div>
				</section>
			)}

			{!isElectronicButton && (
				<>
					<section>
						{dworkingConditionsCode && dworkingConditionsCode !== '0' ? (
							<>
								<div className={styles.labelHint}>
									<Title>{t('dangerous_working_document')}</Title>{' '}
									<Tooltip
										placement="rightTop"
										color="white"
										overlayInnerStyle={{ color: '#5B6880', width: '320px' }}
										title={t('download_button_hint')}
									>
										<span>
											<Info className={styles.hintIcon} />
										</span>
									</Tooltip>
								</div>
								<ConfirmDocument
									getDocument={getDocument}
									getErrorSize={getErrorSize}
									fileType={'HARMFULWORK'}
									fileList={files}
								></ConfirmDocument>
							</>
						) : null}
						<div className={styles.item}>
							<Title>{t('contracts_tab.additional_information')}</Title>
							<div className={`${styles.fieldsFullWidth}`}>
								<FormikField name={formFieldNames.professionalsSkills}>
									{({ field }) => (
										<Field
											type="text"
											fieldType="multipleSelect"
											label={t('contracts_tab.professionals_skills')}
											placeholder={t('contracts_tab.indicate_professionals_skills')}
											options={professionalSkills?.filter((item) => item.isActual == 1)}
											{...field}
										/>
									)}
								</FormikField>
								<div className={styles.error}>{specSkillsListMessage}</div>
							</div>
							<div className={`${styles.fieldsFullWidth}`}>
								<FormikField name={formFieldNames.personalQualities}>
									{({ field }) => (
										<Field
											type="text"
											fieldType="multipleSelect"
											label={t('contracts_tab.personal_qualities')}
											placeholder={t('contracts_tab.indicate_personal_qualities')}
											options={personalQualities?.filter((item) => item.isActual == 1)}
											{...field}
										/>
									)}
								</FormikField>
								<div className={styles.error}>{skillsListMessage}</div>
							</div>
						</div>
					</section>
					<div className={styles.actions}>
						<Button type="bare" onClick={onCancel}>
							{t('cancel')}
						</Button>
						<Button
							textColor="green"
							borderColor="green"
							transparent
							border
							onClick={() => _onSubmit(false)}
							type="submit"
							disabled={(isEdit && values?.dcontractStatus?.id === 1) || !checkDoc || isError}
						>
							{t('save')}
						</Button>
						<Button type="submit" onClick={() => _onSubmit(true)} disabled={!checkDoc || isError}>
							{t('sign_and_send')}
						</Button>
					</div>
				</>
			)}
		</FormikForm>
	)
}
