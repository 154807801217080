import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, ModalBackButton } from '@/components/Modal'
import { LoadingScreen, LoadingBlocker } from '@/components/Loader'
import { Title } from '@/ui'
import { contractsThunks, contractsSelectors, contractsActions } from '@/entity/contracts'
import { useDigitalSign } from '@/shared'
import { ContractTerminationForm } from './ContractTerminationForm'
import styles from './TerminateContract.module.scss'
import { sendTerminate } from '@/entity/contracts/model/thunks'

export const TerminateContract = ({ onClose, successClose, isEdit = false }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const terminationReasons = useSelector(contractsSelectors.terminationReasons)
	const contract = useSelector(contractsSelectors.contract)
	const isLoadingTerminate = useSelector(contractsSelectors.isLoading)
	const contractIsLoading = useSelector(contractsSelectors.contractIsLoading)
	const isLoading = useSelector(contractsSelectors.terminationIsLoading)
	const isSuccess = useSelector(contractsSelectors.terminationIsSuccess)
	const xml = useSelector(contractsSelectors.terminationXML)
	const { successPayload, onSign, onClearPayload, failedPayload } = useDigitalSign()
	const fullClear = () => {
		isSuccess && successClose()
		dispatch(contractsActions.resetTermination())
		onClearPayload()
	}
	useEffect(() => !terminationReasons && dispatch(contractsThunks.getTerminationReasons()), [])
	useEffect(() => {
		xml && onSign(xml)
		successPayload && !xml && fullClear()
	}, [xml]) /** подписание xml */
	useEffect(() => {
		if (successPayload) dispatch(sendTerminate(successPayload))
	}, [successPayload])
	useEffect(() => {
		/** очистка и выход при удачной операции */
		isSuccess && fullClear()
		return () => fullClear()
	}, [isSuccess])
	useEffect(() => {
		failedPayload && fullClear()
	}, [failedPayload])
	const handleDataSubmission = useCallback(
		(data) =>
			!isLoading &&
			dispatch(
				contractsThunks.submitTerminationData({
					...data,
					bin: contract?.bin,
					externalId: contract?.externalId,
				})
			),
		[]
	)

	return (
		<>
			{(isLoading || contractIsLoading) && <LoadingBlocker />}
			<Modal windowClassName={styles.modal} onClose={onClose} onClickDisable={true}>
				<ModalBackButton onClick={onClose} />
				<div className={`${styles.wrapper} scroll`}>
					<Title>
						{t('termination_employment_contract', {
							contractNumber: contract?.contractNumber,
							registerDate: contract?.registerDate,
						})}
					</Title>
					{terminationReasons && !isLoadingTerminate ? (
						<>
							<div className={styles.row}>
								<p className={styles.dataCol}>{t('worker_iin')}:</p>
								<p className={styles.greyText}>{contract?.iin}</p>
							</div>
							<div className={styles.row}>
								<p className={styles.dataCol}>{t('personal_short_information')}:</p>
								<p className={styles.greyText}>{contract?.fullName}</p>
							</div>
							<div className={'mt-32'}>
								<Title>{t('termination_info')}</Title>
							</div>
							{!isLoadingTerminate ? (
								<ContractTerminationForm
									contract={contract}
									terminationReasons={terminationReasons}
									onSubmit={handleDataSubmission}
									onCancel={onClose}
									isEdit={isEdit}
								/>
							) : (
								<LoadingScreen />
							)}
						</>
					) : (
						<LoadingScreen>
							<Title>
								{t('wait')} <br />
								{t('loading_from_esutd')}
							</Title>
						</LoadingScreen>
					)}
				</div>
			</Modal>
		</>
	)
}
