import { request } from '@/shared/api/request'

export const getDataByBin = (params) => request({ url: '/contract/reorganize/check', method: 'GET', params })

export const getEmployersListToChange = (body, params) =>
	request({ url: '/contract/universalMethods/requestFlData', method: 'POST', body, params })

export const getReorganizeXml = (data) => request({ url: '/contract/reorganize/xmlList', method: 'POST', data })

export const sendSignedXml = (data) => request({ url: '/contract/reorganize/send', method: 'POST', data })

export const getInfoByIin = (body, params) =>
	request({ url: '/contract/universalMethods/requestFlData', method: 'POST', body, params })
